<template>
  <app-collapse-item title="System Details" :is-visible="true">
    <b-row>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="creator"
          v-model="systemDetail.creator"
          label="Creator"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="last-modified-date"
          :value="getDate(systemDetail.last_modified_date)"
          label="Last Modified Date"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import DataFormatService from '@/services/DataFormatService'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    systemDetail: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  methods: {
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
