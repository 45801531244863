import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'
import DataFormatService from '@/services/DataFormatService'

export default class Stage {
  constructor(item = {}) {
    return {
      stage: item.stage || '',
      close_date: DataFormatService.formatUsaDate(item.close_date) || '',
      expected_duration: item.expected_duration || '',
      win_reason: item.win_reason || '',
      probability: item.probability || '',
      day_take_to_close_deal: item.day_take_to_close_deal || '',
      loss_reason: item.loss_reason || '',
      loss_reason_explanation: item.loss_reason_explanation || '',
      planned_start_date: DataFormatService.formatUsaDate(item.planned_start_date) || '',
      planned_end_date: DataFormatService.formatUsaDate(item.planned_end_date) || '',
    }
  }
}
