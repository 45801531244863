export default class MediaHistory {
  constructor(item = {}) {
    return {
      campaign_history_id: item.campaign_history_id !== null && item.campaign_history_id !== undefined ? item.campaign_history_id : '',
      creator_avatar: item.creator_avatar || '',
      date: item.date || '',
      creator_name: item.creator_name || '',
      history_number: item.history_number || '',
    }
  }
}
