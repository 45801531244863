<template>
  <app-collapse-item v-if="document" :is-visible="true" title="Document Section">
    <b-row>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="client-folder-google-drive"
          v-model="document.client_folder_google_drive"
          label="Client Folder Google Drive"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="additional-requirement-url"
          v-model="document.additional_requirement_url"
          label="Additional Requirement URL"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="reporting-url"
          v-model="document.reporting_url"
          label="Reporting URL"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    document: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
}
</script>
