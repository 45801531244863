export default class Opportunity {
  constructor(item = {}) {
    return {
      opportunity_id: item.opportunity_id || '',
      opportunity_name: item.opportunity_name || '',
      stage: item.status || '',
      budget: item.budget || '',
      remaining_amount: item.remaining_amount || '',
      duration: item.duration || 1,
      campaign_id: item.campaign_id || '',
      upsell_budget: item.upsell_budget || '',
      retain_budget: item.retain_budget || '',
    }
  }
}
