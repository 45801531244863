<template>
  <b-card no-body>
    <b-card-body>
      <b-tabs v-model="tabIndex" align="left">
        <Campaign :tab-index="tabIndex" />
        <CampaignProduct
          v-if="$can('Access', 'Campaigns > Products')"
          :tab-index="tabIndex"
        />
        <b-tab
          v-if="$can('Facebook - Access', 'Ads Campaign Syncing')"
          title="Ads Campaign Syncing"
          @click="$router.push({ name: 'ads-campaign-syncing' })"
        />
        <HeroVisionFilter v-if="$can('Access', 'Campaigns > HeroVisionX Filter')" />
        <!--        <PaymentCalendar v-if="$can('Access', 'Opportunities > Payment Calendar')" />-->
        <b-tab
          v-if="$can('Access', 'Wallets')"
          title="Wallets"
          @click="$router.push(
            {
              name: 'wallets-list',
              query: {
                walletType: 'Campaign',
                objectId: $route.params.id,
              },
            },
          )"
        >
          <div />
        </b-tab>
        <ApprovalHistory v-if="$can('Access', 'Campaigns > Approval History')" />
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import Campaign from './components/view/Campaign.vue'
import CampaignProduct from './components/view/CampaignProduct.vue'
import HeroVisionFilter from './components/view/HeroVisionFilter.vue'
// import PaymentCalendar from './components/view/PaymentCalendar.vue'
import ApprovalHistory from './components/view/ApprovalHistory.vue'

export default {
  components: {
    Campaign,
    CampaignProduct,
    HeroVisionFilter,
    // PaymentCalendar,
    ApprovalHistory,
  },
  data() {
    return {
      collapseType: 'border',
      tabIndex: 0,
    }
  },
}
</script>
