var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-tabs',{attrs:{"align":"left"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('Campaign',{attrs:{"tab-index":_vm.tabIndex}}),(_vm.$can('Access', 'Campaigns > Products'))?_c('CampaignProduct',{attrs:{"tab-index":_vm.tabIndex}}):_vm._e(),(_vm.$can('Facebook - Access', 'Ads Campaign Syncing'))?_c('b-tab',{attrs:{"title":"Ads Campaign Syncing"},on:{"click":function($event){return _vm.$router.push({ name: 'ads-campaign-syncing' })}}}):_vm._e(),(_vm.$can('Access', 'Campaigns > HeroVisionX Filter'))?_c('HeroVisionFilter'):_vm._e(),(_vm.$can('Access', 'Wallets'))?_c('b-tab',{attrs:{"title":"Wallets"},on:{"click":function($event){return _vm.$router.push(
          {
            name: 'wallets-list',
            query: {
              walletType: 'Campaign',
              objectId: _vm.$route.params.id,
            },
          }
        )}}},[_c('div')]):_vm._e(),(_vm.$can('Access', 'Campaigns > Approval History'))?_c('ApprovalHistory'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }