export default class Information {
  constructor(item = {}) {
    return {
      opportunity_name: item.opportunity_name || '',
      account_id: item.account_id || '',
      account_name: item.account_name || '',
      client_legal_name: item.client_legal_name || '',
      type: item.type || '',
      lead_source: item.lead_sources || '',
      lead_source_2: item.lead_sources_2 || '',
      market_activity: item.marketing_activity || '',
      marketing_objective: item.marketing_objective || '',
      nature_of_deal: item.nature_of_deal || '',
      campaign_name: item.campaign_name || '',
      campaign_code: item.campaign_code || '',
      campaign_id: item.campaign_id || '',
      opportunity_id: item.opportunity_id || '',
      tax_id: item.tax_id || '',
      synced_quote_id: item.synced_quote_id || '',
      synced_quote: item.synced_quote || '',
    }
  }
}
