import DataFormatService from '@/services/DataFormatService'

export default class Incharge {
  constructor(item = {}) {
    return {
      owner: item.opportunity_owner || '',
      omc1: item.OMC1 || '',
      omc2: item.OMC2 || '',
      omc2_percentage: DataFormatService.formatPercentage(item.OMC2_percent) || '',
      target: item.target || '',
      parent_opportunity: item.parent_opportunity,
      industry: item.industry || '',
      est_rebate: DataFormatService.formatPercentage(item.est_rebate) || '',
      client_segment: item.client_segment || '',
      legal_entity: item.legal_entity || '',
      count_quote: item.count_quote || '',
      account_manager: item.account_manager || '',
      order_number: item.order_number || '',
      currency: item.currency || '',
      forecast_category: item.forecast_category || '',
      role_custom: item.role_custom || '',
    }
  }
}
