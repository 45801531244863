<template>
  <div>
    <div
      v-if="cycle.is_deleted"
      class="bg-light-danger pt-1 pb-1 pl-1 mb-1"
    >
      NOTE: This is an approval request for DELETE Cycle.
    </div>
    <b-row>
      <b-col cols="12" class="mr-0 d-flex">
        <b-button :variant="getBadgeVariant(cycle.cycle_status)">
          {{ cycle.cycle_status }}
        </b-button>
        <h5 class="ml-1" style="margin-top:10px;">
          <b>{{ cycle.cycle_name }} ({{ getDate(cycle.start_date.split(' ')[0]) }} - {{ getDate(cycle.end_date.split(' ')[0]) }}) </b>
        </h5>
      </b-col>
    </b-row>
    <b-row v-if="cycle.remark">
      <b-col cols="12" class="mt-1">
        <div class="bg-light-warning pt-1 pb-1 pl-1">
          {{ cycle.remark }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import DataFormatService from '@/services/DataFormatService'
import Constant from '@/utils/Constant'

export default {
  directives: {
    Ripple,
  },
  props: {
    cycle: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getBadgeVariant(cycleStatus) {
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Active.toLowerCase()) {
        return 'success'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Paused.toLowerCase()) {
        return 'warning'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Onboarding.toLowerCase()) {
        return 'info'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.OnHold.toLowerCase())  {
        return 'primary'
      }

      return 'danger'
    },
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
