<template>
  <b-modal
    id="prompt-list-modal"
    ref="prompt-list-modal"
    title="Prompt List"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    @shown="getPromptList"
    @hidden="promptList = []"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <div
      v-if="promptList.length"
      class="prompt-list"
    >
      <div
        v-for="prompt in promptList"
        :key="prompt.id"
        class="box"
      >
        <div
          :id="`prompt-name-${prompt.id}`"
          class="prompt-name"
        >
          {{ prompt.name }}
        </div>

        <div class="prompt-detail">
          <feather-icon
            icon="TerminalIcon"
            size="24"
          />
          <div class="prompt-action">
            <HeroTextarea
              id="prompt-text"
              class="prompt-text"
              :value="prompt.prompt"
              rows="4"
              disabled
              readonly
            />
            <div
              class="copy-icon"
              @click="!isCopied && updatePromptUsage(prompt.id, prompt.prompt)"
            >
              <feather-icon
                :id="prompt.id"
                icon="CopyIcon"
                size="24"
                stroke="#7367f0"
              />
              <b-tooltip
                class="w-auto"
                :target="prompt.id"
                triggers="hover"
              >
                {{ isCopied ? 'Copied' : 'Copy' }}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      No Prompt found for this product.
    </div>
  </b-modal>
</template>

<script>
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'

export default {
  components: { HeroTextarea },
  props: {
    productId: {
      type: String,
      default: '',
    },
    campaignId: {
      type: String,
      default: '',
    },
    campaignProductId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      isCopied: false,
      promptList: [],
    }
  },
  methods: {
    async getPromptList() {
      try {
        this.showOverlay = true
        const { data: { data: promptList } } = await axiosInstance.get(`prompts/promptsByProduct/${this.productId}/${this.campaignId}`)
        this.promptList = promptList
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async updatePromptUsage(promptId, promptText) {
      try {
        this.isCopied = true
        await navigator.clipboard.writeText(promptText)
        await axiosInstance.post('prompt-usage/update', {
          prompt_id: promptId,
          product_id: this.productId,
          campaign_id: this.campaignId,
          campaign_product_id: this.campaignProductId,
        })
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isCopied = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#prompt-list-modal {
  .box {
    background: white;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #D8D6DE;
  }

  .prompt-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-height: calc(100vh - 116px);
    overflow-y: auto;
    .prompt-name {
      font-size: 1rem;
      font-weight: 500;
    }
    .prompt-detail {
      display: flex;
      column-gap: 8px;
      margin-top: 12px;
      .prompt-action {
        position: relative;
        flex: 1 0 0%;
      .prompt-text {
        margin-bottom: 0;
      }
        .copy-icon {
          position: absolute;
          right: 16px;
          bottom: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
