<template>
  <b-tab title="Approval History">

    <!-- !! Table Header -->
    <div class="mx-1">
      <b-row>
        <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
      </b-row>
    </div>

    <!-- !! Table -->
    <b-row class="my-1">
      <b-col cols="12">
        <b-table
          show-empty
          :items="items"
          :fields="tableConfig.fields"
          striped
          responsive
          bordered
          :sticky-header="true"
          :no-border-collapse="true"
          style="font-weight: normal; font-size: 12px;"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          @sort-changed="doTableSort"
        >
          <template #cell(id)="data">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="showApprovalPopup(data.item.id)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>

    <PendingApproval :approval-id="approvalId" :modal-id="'modal-approval-list'" />

  </b-tab>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import ApprovalHistoryFactory from '@/factories/Campaign/ApprovalHistoryFactory'
import PendingApproval from '@/views/heroai/campaigns/components/view/Campaign/PendingApproval/Popup.vue'

export default {
  components: {
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    PendingApproval,
  },
  data() {
    return {
      showOverlay: true,
      items: [],
      approvalId: '',
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'edited_at',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Approval Status',
            key: 'approval_status',
            sortable: true,
          },
          {
            label: 'Edited By',
            key: 'edited_by_name',
            sortable: true,
          },
          {
            label: 'Edited At',
            key: 'edited_at',
            sortable: true,
          },
          {
            label: 'Approved By',
            key: 'approved_by_name',
            sortable: true,
          },
          {
            label: 'Approved At',
            key: 'approved_at',
            sortable: true,
          },
          {
            label: 'Disapproved By',
            key: 'disapproved_by_name',
            sortable: true,
          },
          {
            label: 'Disapproved At',
            key: 'disapproved_at',
            sortable: true,
          },
          {
            label: 'Action',
            key: 'id',
            class: 'text-center',
          },
        ],
      },
    }
  },
  watch: {
    '$store.state.heroAiCampaign.isPendingApproval': {
      async handler() {
        this.doLoadData()
      },
    },
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },
    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },
    async doLoadData() {
      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig
        const qparam = {
          perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection, campaign_id: this.$route.params.id, approval_status: 'All',
        }
        const response = await AxiosService.get(Constant.apiURL.approvalHistory, qparam)

        if (response.status === true) {
          this.tableConfig.currentPage = Number(response.data.current_page)
          this.tableConfig.perPage = Number(response.data.per_page)
          this.tableConfig.totalRows = Number(response.data.total)
          this.items = ApprovalHistoryFactory.createFromJsonArray(response.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.items = []
        this.tableConfig.totalRows = 0
      }
    },
    showApprovalPopup(approvalId) {
      this.approvalId = approvalId
      this.$bvModal.show('modal-approval-list')
    },
  },
}
</script>
