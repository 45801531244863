<template>
  <b-modal
    id="herovision-filter-email-modal"
    ref="herovision-filter-email-modal"
    title="Email"
    centered
    hide-footer
    no-close-on-backdrop
    size="md"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <validation-observer ref="formA">
      <b-form @submit.stop.prevent>
        <b-row
          v-for="(email, index) in filter.email_lead"
          :key="index"
          class="mb-0"
        >
          <b-col cols="10">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <HeroInputText
                :id="`email-${index}`"
                v-model="filter.email_lead[index]"
                :required="true"
                :clearable="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <HeroButtonAction type="button" variant="primary" @click="deleteRow(index)">
              <feather-icon icon="TrashIcon" />
            </HeroButtonAction>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" offset-sm="9" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="addRow">
              Add
            </HeroButtonAction>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" offset="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  email,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    filter: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      email,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['herovision-filter-email-modal'].hide()
      this.$emit('success')
    },

    addRow() {
      this.filter.email_lead.push('')
    },

    deleteRow(index) {
      this.filter.email_lead.splice(index, 1)
    },

    async validationForm() {
      const validationResult = await this.$refs.formA.validate()

      if (validationResult) {
        this.$refs['herovision-filter-email-modal'].hide()
      }
    },
  },
}
</script>
