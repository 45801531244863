import CampaignHistory from '@/models/History/CampaignHistory'

export default class CampaignHistoryFactory {
  static createFromJson(json) {
    return new CampaignHistory(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      if (item && item.date) {
        jsonData.push(CampaignHistoryFactory.createFromJson(item))
      }
    })

    return jsonData
  }
}
