import MediaHistory from '@/models/History/MediaHistory'

export default class MediaHistoryFactory {
  static createFromJson(json) {
    return new MediaHistory(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      if (item.media_plan_history && item.media_plan_history.length > 0) {
        item.media_plan_history.forEach(itemmedia => {
          if (itemmedia && itemmedia.date) {
            jsonData.push(MediaHistoryFactory.createFromJson(itemmedia))
          }
        })
      }
    })

    return jsonData
  }
}
