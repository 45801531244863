import ApprovalHistory from '@/models/ApprovalHistory'

export default class ApprovalHistoryFactory {
  static createFromJson(json) {
    return new ApprovalHistory(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(ApprovalHistoryFactory.createFromJson(item))
    })

    return jsonData
  }
}
