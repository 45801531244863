<template>
  <b-card
    v-if="cycle != null && isCycleShow"
    style="border: 2px solid;"
  >
    <CycleHeader
      :active-channel-id="activeChannelId"
      :campaign-enddate="campaignEnddate"
      :campaign-startdate="campaignStartdate"
      :cycle="cycle"
      :cycle-index="cycleIndex"
      @selectChange="$emit('selectChange')"
    />
    <CycleView
      :cycle-number="cycle.cycle_number"
      :client-pay-for-media="clientPayForMedia"
      :cycle-index="cycleIndex"
      :products="cycle.products"
      :kpi-options="kpiOptions"
      :allproducts="allproducts"
      :product-options="productOptions"
      :cycle="cycle"
      :campaign-product-options="campaignProductOptions"
      class="mx-1"
    />
  </b-card>
</template>
<script>
import CycleHeader from './Cycle/Edit/Header.vue'
import CycleView from './Cycle/Edit/Cycle.vue'

export default {
  components: {
    CycleHeader,
    CycleView,
  },
  props: {
    campaignProductOptions: {
      type: Array,
      default() {
        return []
      },
    },
    activeChannelId: {
      type: String,
    },
    cycle: {
      type: Object,
      default: null,
    },
    campaignStartdate: {
      type: String,
      default: null,
    },
    campaignEnddate: {
      type: String,
      default: null,
    },
    cycleIndex: {
      type: Number,
      required: true,
    },
    clientPayForMedia: {
      type: Boolean,
      default: false,
    },
    cycles: {
      type: Array,
      default() {
        return []
      },
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
    allproducts: {
      type: Array,
      default() {
        return []
      },
    },
    productOptions: {
      type: Array,
      default() {
        return []
      },
    },
    isCycleShow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
