<template>
  <app-collapse-item title="Chatbot Section" :is-visible="true">
    <b-row>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="existing-bot"
          v-model="chatbot.existing_bot"
          label="If there is any existing bot and/or auto-message on the page, can we remove?"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="chatbot-launch-date"
          :value="getDate(chatbot.chatbot_launch_date)"
          label="Chatbot Launch Date"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="flow-type"
          v-model="chatbot.flow_type"
          label="Please select flow type"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DataFormatService from '@/services/DataFormatService'

export default {
  components: {
    HeroDisplayText,
    AppCollapseItem,
  },
  props: {
    chatbot: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  methods: {
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
