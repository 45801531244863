import Contact from '@/models/Contact'

export default class ContactFactory {
  static createFromJson(json) {
    return new Contact(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(ContactFactory.createFromJson(item))
    })

    return jsonData
  }
}
