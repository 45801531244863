<template>
  <app-collapse-item :is-visible="true" title="Opportunity History">
    <b-table
      class="mt-2"
      show-empty
      striped
      sticky-header="100%"
      :responsive="true"
      :bordered="true"
      :items="opportunityHistories"
      :fields="fields"
      :per-page="0"
    >
      <template #cell(name)="data">
        <router-link :to="`/opportunities/${data.item.opportunityId}`">
          {{ data.item.name }}
        </router-link>
      </template>
      <template #cell(stage)="data">
        <b-badge variant="success">
          {{ data.item.stage }}
        </b-badge>
      </template>
      <template #cell(amount)="data">
        {{ formatMoney(data.item.amount) }}
      </template>
    </b-table>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DataFormatService from '@/services/DataFormatService'
import OpportunityHistoryFactory from '@/factories/Campaign/OpportunityHistory'

export default {
  components: {
    AppCollapseItem,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Opportunity Name', thClass: 'text-center' },
        {
          key: 'stage', label: 'SF Stage', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'action', label: 'Action', thClass: 'text-center' },
        {
          key: 'duration', label: 'Duration', thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'amount', label: 'Amount (THB)', thClass: 'text-center', tdClass: 'text-right',
        },
        { key: 'editorName', label: 'Edited By', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    opportunityHistories() {
      return OpportunityHistoryFactory.createFromJsonArray(this.$store.state.heroAiCampaign.opportunityHistory)
    },
  },
  methods: {
    formatMoney: DataFormatService.formatMoney,
  },
}
</script>
<style>
.ml-auto {
  margin-left: auto;
}
.d-block {
  display: block;
}
.hightlight-tab > a {
  overflow: hidden;
  background-color: #ddd;
  border-radius: 9px;
  margin: 5px;
}
.hightlight-tab:hover > a {
  background-color: #ddd;
}
.hightlight-tab-error > a {
  overflow: hidden;
  background-color: #ea5455 !important;
  border-radius: 9px;
  margin: 5px;
  color: #ddd !important;
}
.owner-avatar {
  height: 28px;
  border-radius: 50%;
}
</style>
