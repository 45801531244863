<template>
  <b-modal
    id="cycle-filter-modal"
    ref="cycle-filter-modal"
    title="Cycle Filters"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <HeroVueSelect
      id="cycle-filter"
      v-model="filter.cycleListFilter"
      :multiple="false"
      :clearable="false"
      :options="cycleListFilterOptions"
    />
    <template v-if="currentCycleListFilter === 'select_date'">
      <div>Month range</div>
      <b-row class="mb-2 mt-1">
        <b-col>
          <HeroMonthYearPicker
            v-model="filter.start"
            placeholder="Select start month"
            :min-year="2018"
            :current-year="new Date().getFullYear() + 2"
            @input="onMonthYearPickerChange('start')"
          />
        </b-col>
        <b-col>
          <HeroMonthYearPicker
            v-model="filter.end"
            placeholder="Select end month"
            :min-year="2018"
            :current-year="new Date().getFullYear() + 2"
            @input="onMonthYearPickerChange('end')"
          />
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col cols="12">
        <HeroButtonAction
          type="button"
          variant="primary"
          :is-loading="isFiltering"
          @click="onSubmit"
        >
          Submit
        </HeroButtonAction>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroMonthYearPicker from '@/views/components/form/date-pickers/HeroMonthYearPicker.vue'
import { doubleRaf } from '@/utils/helper'

export default {
  components: {
    HeroVueSelect,
    HeroButtonAction,
    HeroMonthYearPicker,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    initialCycleListFilter: {
      type: String,
      default: 'by_contract',
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      isFiltering: false,
      filter: {
        cycleListFilter: this.initialCycleListFilter,
        start: {
          month: '',
          year: '',
        },
        end: {
          month: '',
          year: '',
        },
      },
      cycleListFilterOptions: [
        { text: 'By Active', value: 'by_active' },
        { text: 'By Contract', value: 'by_contract' },
        { text: 'Previous Contract', value: 'previous_contract' },
        { text: 'View All', value: 'view_all' },
        { text: 'Select Date', value: 'select_date' },
      ],
    }
  },
  computed: {
    currentCycleListFilter() {
      return this.filter.cycleListFilter
    },
  },
  watch: {
    currentCycleListFilter(newValue) {
      if (newValue !== 'select_date') {
        this.filter.start = {
          month: '',
          year: '',
        }
        this.filter.end = {
          month: '',
          year: '',
        }
      }
    },
  },
  methods: {
    doCloseModal() {
      this.$refs['cycle-filter-modal'].hide()
    },

    onSubmit() {
      this.isFiltering = true
      doubleRaf(() => {
        this.$emit('onSubmit', this.filter)
        this.doCloseModal()
        this.isFiltering = false
      })
    },

    onMonthYearPickerChange(flagType) {
      const clearData = () => {
        if (flagType === 'start') {
          this.filter.end = {
            month: '',
            year: '',
          }
        } else {
          this.filter.start = {
            month: '',
            year: '',
          }
        }
      }
      if (+this.filter.end.year < +this.filter.start.year) {
        clearData()
      }

      if (
        this.filter.end.year === this.filter.start.year
            && +this.filter.end.month < +this.filter.start.month
      ) {
        clearData()
      }
    },
  },
}
</script>
