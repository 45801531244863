<template>
  <app-collapse-item title="SEO Requirement Section" :is-visible="true">
    <b-row>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-google-analytic-account"
          :value="seoRequirements.seo_google_analytic_account"
          label="Google Analytic Account"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-google-search-console-account"
          :value="seoRequirements.seo_google_search_console_account"
          label="Google Search Console Account"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-real-time-dashboard-access-email"
          :value="seoRequirements.seo_real_time_dashboard_access_email.join(', ')"
          label="Real Time Dashboard Access Email"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-focus-page"
          :value="seoRequirements.seo_focus_page"
          label="Focus Page"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-brand-corporate-identity"
          :value="seoRequirements.seo_brand_corporate_identity"
          label="Brand Corporate Identity"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-brand-keyword"
          :value="seoRequirements.seo_brand_keyword"
          label="Brand Keyword"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-core-product-or-services"
          :value="seoRequirements.seo_core_product_or_services"
          label="Core Product Or Services"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-communication-marketing-plan"
          :value="seoRequirements.seo_communication_marketing_plan"
          label="Communication Marketing Plan"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-industry-leader"
          :value="seoRequirements.seo_industry_leader"
          label="Industry Leader"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-main-competitors"
          :value="seoRequirements.seo_main_competitors.join(', ')"
          label="Main Competitors"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-note"
          :value="seoRequirements.seo_note"
          label="Note"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-briefing-url"
          :value="seoRequirements.seo_briefing_url"
          label="SEO Briefing URL"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="seo-client-report-url"
          :value="seoRequirements.seo_client_report_url"
          label="SEO Client Report URL"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DataFormatService from '@/services/DataFormatService'
import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'
import { BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    HeroDisplayText,
    AppCollapseItem,
  },
  props: {
    seoRequirements: {
      type: Object,
      require: true,
      default: () => new SEORequirementsSection({}),
    },
  },
  methods: {
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
