<template>
  <app-collapse-item v-if="cycle != null" :title="''" :is-visible="visibleCycle">
    <template v-slot:action>
      <div class="text-left w-100" @click="visibleCycle = !visibleCycle">
        <CycleHeader :cycle="cycle" />
      </div>
    </template>
    <CycleView :kpi-options="kpiOptions" :products="cycle.products" :columns="columns" :updateimporttype="updateimporttype" class="mx-1" />
  </app-collapse-item>
</template>
<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CycleHeader from './Header.vue'
import CycleView from './Cycle.vue'

export default {
  components: {
    AppCollapseItem,
    CycleHeader,
    CycleView,
  },
  props: {
    cycle: {
      type: Object,
      default: null,
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
    updateimporttype: {
      type: Boolean,
      default: true,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visibleCycle: true,
    }
  },
}
</script>
