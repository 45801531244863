import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'
import DataFormatService from '@/services/DataFormatService'

export default class ClientBillingProcess {
  constructor(item = {}) {
    return {
      client_requirements: item.client_requirements || '',
      finance_process: item.finance_process || '',
      invoice_placement_date_for_cycle_1: DataFormatService.formatUsaDate(item.invoice_placement_date) || '',
      invoice_payment_date_for_cycle_1: DataFormatService.formatUsaDate(item.invoice_payment_date) || '',
    }
  }
}
