<template>
  <app-collapse-item :is-visible="true" title="Creative Briefing">
    <b-row>
      <b-col cols="12">
        <HeroDisplayText
          id="artwork-per-month"
          :value="creative.creative_briefing_link"
          label="Creative Briefing Link"
          class="mb-0"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="artwork-per-month"
          :value="creative.artwork_per_month"
          label="Do you commit amount of artwork per month"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="product"
          :value="creative.product"
          label="Product, service, branch information document"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="any-faq"
          :value="creative.any_faq"
          label="Any FAQ questions to add to the flow?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="any-promotion"
          :value="creative.any_promotion"
          label="Any promotion offer to show the user?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="pieces"
          :value="creative.pieces.toString()"
          label="How many pieces?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="lead-data"
          :value="creative.lead_item"
          label="Lead data requirements(email, phone)?"
        />
      </b-col>
    </b-row>
    <div
      v-if="$route.name === 'campaign-detail' && isAssigned && questionList"
      class="h-line"
    />
    <b-spinner
      v-if="$route.name === 'campaign-detail' && isLoading"
      variant="primary"
      label="Spinning"
      class="mt-1"
    />
    <template v-if="$route.name === 'campaign-detail' && isAssigned && questionList && !isLoading">
      <div class="history-control">
        <HeroVueSelect
          id="last-edited-at"
          v-model="lastEditedAt"
          :label="questionList[`${currentLanguage}_last_edited_at`]"
          :options="historyOptions"
          :clearable="false"
          @input="getCreativeBriefing"
        />
        <HeroDisplayText
          id="last-edited-by"
          :value="lastEditedBy"
          :label="questionList[`${currentLanguage}_last_edited_by`]"
        />
      </div>
      <app-collapse-item
        v-for="allQuestion in questionList.all_questions"
        :key="allQuestion.id"
        :is-visible="true"
        :title="allQuestion.title"
      >
        <b-row>
          <template v-for="question in allQuestion.questions">
            <b-col
              :key="question.key"
              :cols="12"
              :md="question.type === 'list' ? 12 : 6"
            >
              <HeroDisplayText
                :id="question.key"
                :label="question[`${currentLanguage}_label`]"
                :tag-line="question[`${currentLanguage}_tagLine`]"
              >
                <template v-if="question.type !== 'list'">
                  {{ question.answer || '-' }}
                </template>
                <div
                  v-else
                  class="item-list-display"
                >
                  <template v-if="question.answer.length">
                    <div
                      v-for="(answer, index) in question.answer"
                      :key="index"
                      class="item"
                    >
                      <div
                        v-for="component in question.components"
                        :key="component.key"
                      >
                        <span class="sub-label">{{ component.placeholder }}:</span> {{ answer[component.key] }}
                      </div>
                    </div>
                  </template>
                  <span v-else>-</span>
                </div>
              </HeroDisplayText>
            </b-col>
          </template>
        </b-row>
      </app-collapse-item>
    </template>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import axiosInstance from '@/libs/axiosInstance'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import moment from 'moment/moment'

export default {
  components: {
    HeroVueSelect,
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    creative: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      lastEditedAt: '',
      historyOptions: [],
      lastEditedBy: '',
      currentLanguage: 'th',
      userInformation: {
        fullName: '',
        campaignName: '',
      },
      questionList: null,
    }
  },
  computed: {
    opportunityId() {
      return this.creative.campaign_creative_brief.opportunity_id
    },

    creativeBriefingId() {
      return this.creative.campaign_creative_brief.unique_id || ''
    },

    isAssigned() {
      return !!this.creativeBriefingId
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.userInformation.fullName = this.$store.getters['heroAiAuthentications/fullName'] || ''
      if (this.isAssigned) {
        this.isLoading = true
        await this.getCreativeBriefingQuestions()
        await this.getCreativeBriefingHistory()
        await this.getCreativeBriefing()
        this.isLoading = false
      }
    },

    async getCreativeBriefingQuestions() {
      try {
        const { data } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.questions)
        this.questionList = { ...data }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefingHistory() {
      try {
        const { data: { data: histories } } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.history(this.creativeBriefingId))
        this.historyOptions = histories.map(history => ({
          text: moment(history.created_at).add(7, 'hours').format('YYYY-MM-DD hh:mm:ss'),
          value: history.created_at,
        }))

        this.lastEditedAt = this.historyOptions[0].value
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async getCreativeBriefing() {
      try {
        const apiURL = `${Constant.apiURL.campaignCreativeBrief.show(this.opportunityId, this.creativeBriefingId)}?created_at=${this.lastEditedAt}`
        const { data } = await axiosInstance.get(apiURL)

        this.currentLanguage = data.data.language === 'Thai' ? 'th' : 'en'
        this.lastEditedBy = data.data.created_by
        this.userInformation.campaignName = data.data.campaign_name || ''

        this.questionList.all_questions = this.questionList.all_questions.map(allQuestion => ({
          ...allQuestion,
          questions: allQuestion.questions.map(question => ({
            ...question,
            answer: data.data[question.key] || (question.type === 'list' ? [] : ''),
          })),
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.h-line {
  height: 1px;
  width: 100%;
  background: #D8D6DE;
  margin-block: 10px;
}

.history-control {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media(min-width: 768px) {
    flex-direction: row;
    column-gap: 6px;
  }
  .form-group {
    flex: 1 0 0;
    width: 100%;
  }
}

.item-list-display {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  .item {
    .sub-label {
      font-weight: 500;
    }
  }
}
</style>
