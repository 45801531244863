<template>
  <b-modal id="modal-upsell" title="Upsell Campaign" scrollable cancel-variant="outline-secondary" size="xl" button-size="sm" @show="resetModal">
    <b-overlay
      no-wrap
      spinner-variant="primary"
      :show="isLoading"
    />
    <HeroInputText v-if="!backButton" id="filter" v-model="search" placeholder="Search..." />
    <b-table show-empty :items="listings" :fields="fields" striped responsive size="xl" :busy="isLoading">
      <template #head(upsell_budget)="data">
        <div :style="`width: ${data.field.thwidth};`">
          {{ data.label }}
        </div>
      </template>
      <template v-slot:cell(selected)="row">
        <b-form-radio v-model="opportunity" name="opportunity" :value="row.item" />
      </template>
      <template v-slot:cell(stage)="row">
        <b-badge variant="success">
          {{ row.item.stage }}
        </b-badge>
      </template>
      <template v-slot:cell(upsell_budget)="row">
        <HeroInputNumber
          id="upsell_budget"
          v-model="row.item.upsell_budget"
          type="text" name="upsell_budget"
          placeholder="Upsell"
          :required="true"
          :state="upsellError ? false : null"
          :invalid-feedback="upsellError || ''"
          @input="isBudgetExceed"
        />
      </template>
      <template v-slot:cell(budget)="row">
        {{ formatMoney(row.item.budget) }}
      </template>
      <template v-if="!secondstep" v-slot:cell(remaining_amount)="row">
        {{ formatMoney(row.item.remaining_amount) }}
      </template>
      <template v-else v-slot:cell(remaining_amount)>
        {{ formatMoney(remainingAmount) }}
      </template>
    </b-table>
    <template #modal-footer>
      <div class="w-100">
        <template v-if="backButton">
          <b-button variant="dark" class="float-left" @click="resetModal()">
            Back
          </b-button>
          <b-button variant="success" :disabled="!!upsellError || opportunity.upsell_budget <= 0" class="float-right ml-1" @click="next()">
            Next
          </b-button>
        </template>
        <b-button v-else variant="success" :disabled="!opportunity.opportunity_id" class="float-right ml-1" @click="next()">
          Next
        </b-button>
        <b-button variant="outline-primary" class="float-right ml-1" @click="$bvModal.hide('modal-upsell')">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import AxiosService from '@/services/AxiosService'
import OpportunityFactory from '@/factories/Campaign/Opportunity'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import Store from '@/store/index'
import Constant from '@/utils/Constant'
import DataFormatService from '@/services/DataFormatService'

export default {
  name: 'Opportunities',
  components: { HeroInputText, HeroInputNumber },
  data() {
    return {
      isLoading: false,
      upsellError: false,
      search: '',
      opportunity: '',
      fields: [
        { key: 'selected', label: '', sortable: false },
        'campaign_id',
        'opportunity_name',
        'budget',
        { key: 'remaining_amount', label: 'Remaining' },
      ],
      opportunities: [],
      backButton: false,
      secondstep: false,
    }
  },
  computed: {
    listings() {
      return this.opportunities.filter(opportunity => opportunity.opportunity_name.toLowerCase().includes(this.search.toLowerCase()) || opportunity.campaign_id.toLowerCase().includes(this.search.toLowerCase()))
    },
    remainingAmount() {
      return this.opportunity.remaining_amount - this.opportunity.upsell_budget
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
  },
  methods: {
    isBudgetExceed() {
      this.upsellError = false
      if (this.opportunity.remaining_amount < this.opportunity.upsell_budget) {
        this.upsellError = 'Please enter Upsell Budget not over Remaining'
      }
    },
    async next() {
      if (!this.backButton) {
        this.fields = [
          'opportunity_name',
          { key: 'stage', label: 'SF Stage' },
          'budget',
          { key: 'upsell_budget', label: 'Upsell Budget', thwidth: '130px' },
          { key: 'remaining_amount', label: 'Remaining' },
        ]
        this.opportunities = []
        this.opportunities.push(this.opportunity)
        this.secondstep = true
        this.backButton = true
      } else {
        this.isLoading = true
        const data = {
          campaign_additional_budget: this.opportunity.upsell_budget,
          opportunity_id: this.opportunity.opportunity_id,
        }
        const response = await AxiosService.patch(`${Constant.apiURL.campaign.upsell.update}/${this.$route.params.id}`, {}, data)
        if (response.status) {
          this.$emit('upsellSuccess', true)
          this.$bvModal.hide('modal-upsell')
        }
        this.isLoading = false
        this.secondstep = false
      }
    },
    async resetModal() {
      this.fields = [
        { key: 'selected', label: '', sortable: false },
        'campaign_id',
        'opportunity_name',
        { key: 'stage', label: 'SF Stage' },
        'budget',
        { key: 'remaining_amount', label: 'Remaining' },
      ]
      this.search = ''
      await this.doLoadData()
      this.backButton = false
      this.opportunity = ''
      this.secondstep = false
    },
    async doLoadData() {
      this.isLoading = true
      const accountId = Store.getters['heroAiCampaign/getOpportunityOverview'].account_id
      const response = await AxiosService.get(`${Constant.apiURL.campaign.upsell.opportunities}/${accountId}`)
      if (response.status) {
        this.opportunities = OpportunityFactory.createFromJsonArray(response.data)
      }
      this.isLoading = false
      this.secondstep = false
    },
  },
}
</script>
