export default class ApprovalHistory {
  constructor(item = {}) {
    return {
      approval_status: item.approval_status || '',
      approval_type: item.approval_type || '',
      approved_at: item.approved_at || '',
      approved_by: item.approved_by || '',
      approved_by_id: item.approved_by_id || '',
      approved_by_name: item.approved_by_name || '',
      campaign_id: item.campaign_id || '',
      campaign_name: item.campaign_name || '',
      disapproved_at: item.disapproved_at || '',
      disapproved_by_id: item.disapproved_by_id || '',
      disapproved_by_name: item.disapproved_by_name || '',
      edit_api_response: item.edit_api_response || '',
      edited_at: item.edited_at || '',
      edited_by_id: item.edited_by_id || '',
      edited_by_name: item.edited_by_name || '',
      history: item.history || '',
      id: item.id || '',
      remark: item.remark || '',
      action: item.action || '',
    }
  }
}
