<template>
  <b-modal
    id="modal-import"
    title="Import Excel"
    scrollable
    cancel-title="Cancel"
    ok-title="Next"
    cancel-variant="outline-secondary"
    size="md"
    button-size="sm"
    @show="resetModal"
  >
    <b-row class="mt-1">
      <b-col cols="12" md="3">
        <b-badge variant="info" class="mr-1 height mb-0 p-1 mt-2" :title="pricingmodel">
          {{ pricingmodel }}
        </b-badge>
      </b-col>
      <b-col cols="12" md="9">
        <HeroInputNumber
          id="buffer"
          v-model="buffer"
          placeholder="Buffer %"
          label="Buffer %"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-form-file
          v-model="fileObj"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept=".xlsx"
          class="mb-1"
        />
        <a :href="sampleFileURL" target="_blank">Media plan example</a>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="d-flex justify-content-end col-gap-4">
        <b-button
          variant="outline-primary"
          @click="$bvModal.hide('modal-import')"
        >
          Cancel
        </b-button>
        <HeroButtonAction
          v-if="!importSuccess"
          type="button"
          variant="primary"
          :is-loading="isFileImporting"
          @click="importFile()"
        >
          Validate
        </HeroButtonAction>
        <b-button
          v-else
          variant="primary"
          @click="importModalClose"
        >
          Import
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import Store from '@/store/index'
import Constant from '@/utils/Constant'
import AxiosService from '@/services/AxiosService'
import SweetAlert from '@/services/SweetAlert'

export default {
  name: 'Import',
  components: {
    HeroInputNumber,
    HeroButtonAction,
  },
  data() {
    return {
      tableConfig: {
        search: '',
      },
      sampleFileURL: 'https://docs.google.com/spreadsheets/d/17OE6B-zK6GCwwmNiMu8ixydfHwDp7d-ax-5njJg_VFc/edit#gid=2104366387',
      backButton: false,
      pricingmodel: '',
      fileObj: null,
      buffer: '0',
      importSuccess: false,
      responseMessage: '',
      isFileImporting: false,
    }
  },
  methods: {
    async importFile() {
      this.isFileImporting = true

      const responseExist = await AxiosService.get(`${Constant.apiURL.salesforceCampaignPendingRequestExist}/${this.$route.params.id}`)
      if (responseExist.status && responseExist.data.pending_request_exist) {
        const { isConfirmed } = await this.$swal({
          title: 'Approval request already exist. Do you want to override?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          showCloseButton: true,
        })

        if (isConfirmed) {
          await this.uploadCycleFile()
        }
      } else {
        await this.uploadCycleFile()
      }

      this.isFileImporting = false
    },
    async uploadCycleFile() {
      const formData = new FormData()
      formData.append('uploadMediaFile', this.fileObj)
      formData.append('buffer', this.buffer)
      const response = await AxiosService.post(`${Constant.apiURL.exportCampaign}/${this.$route.params.id}/importMediaPlans`, null, formData)
      if (response.status) {
        this.importSuccess = true
        this.responseMessage = response.message
        this.$emit('importSuccess', this.importSuccess)
      } else {
        this.fileObj = null
      }
    },
    importModalClose() {
      this.$swal({ ...SweetAlert.success, text: this.responseMessage })
      this.$bvModal.hide('modal-import')
    },
    resetModal() {
      const OpportunityOverview = Store.getters['heroAiCampaign/getOpportunityOverview']
      this.pricingmodel = OpportunityOverview.pricing_model
      this.importSuccess = false
      this.$emit('importSuccess', this.importSuccess)
    },
  },
}
</script>
