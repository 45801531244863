<template>
  <b-row class="mx-1">
    <b-col cols="12" md="3">
      <label style="font-size: 1rem; font-weight: 600;">Account Name</label> <br>
      <router-link :to="`/accounts/${opportunity.account_id}`" target="_blank">
        {{ opportunity.account_name }}
      </router-link>
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="account-number"
        v-model="opportunity.account_number"
        label="Account Number"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="duration"
        :value="opportunity.duration.toString()"
        label="Duration"
      />
    </b-col>
    <b-col cols="12" md="3">
      <label style="font-size: 1rem; font-weight: 600;">Opportunity Name</label> <br>
      <router-link :to="`/opportunities/${opportunity.opt_id}`" target="_blank">
        {{ opportunity.opportunity_name }}
      </router-link>
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="pricing-model"
        v-model="opportunity.management_fee_type"
        label="Pricing Model"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="management-fee"
        :value="opportunity.management_fee.toString()"
        label="Management fee(%)"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="industry"
        v-model="opportunity.industry"
        label="Industry"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="objective-name"
        v-model="opportunity.objective_name"
        label="Objective"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="country"
        v-model="opportunity.country"
        label="Country"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="payment-terms"
        v-model="opportunity.payment_terms"
        label="Payment Terms"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="opportunity-id"
        v-model="opportunity.opportunity_id"
        label="Opportunity ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <b-form-group label-for="client_requirements">
        <template v-slot:label>
          <span style="font-size: 1rem; font-weight: 600;">
            Client Requirements
          </span>
        </template>
        <ul
          v-if="clientRequirements && clientRequirements.length"
          class="pl-2"
        >
          <li
            v-for="clientRequirement in clientRequirements"
            :key="clientRequirement"
          >
            {{ clientRequirement }}
          </li>
        </ul>
        <div v-else>
          -
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import { BFormGroup } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    HeroDisplayText,
  },
  props: {
    opportunity: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    clientRequirements() {
      if (!this.opportunity.client_requirements) return []

      let clientRequirements = JSON.parse(this.opportunity.client_requirements)

      if (!clientRequirements) return []

      if (Array.isArray(clientRequirements)) {
        clientRequirements = clientRequirements.filter(clientRequirement => !!clientRequirement)
        return clientRequirements
      }

      clientRequirements = clientRequirements.split(';').filter(clientRequirement => !!clientRequirement)
      return clientRequirements
    },
  },
}
</script>
