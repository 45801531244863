import SalesOrder from '@/models/Opportunity/SalesOrder'

export default class SalesOrderFactory {
  static createFromJson(json) {
    return new SalesOrder(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(SalesOrderFactory.createFromJson(item))
    })

    return jsonData
  }
}
