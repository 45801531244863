export default class SalesOrder {
  constructor(item = {}) {
    return {
      id: item.id,
      opportunity_id: item.opportunity_id || '',
      invoice_number: item.invoice_number || '',
      total_amount: item.total_amount || '',
      quote_number: item.quote_number || '',
      status: item.status || '',
      total_amount_with_vat: item.total_amount_with_vat || '',
      sales_id: item.sales_id || '',
    }
  }
}
