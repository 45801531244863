import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class SalesHistory {
  constructor(item = {}) {
    return {
      id: item.id || '',
      opportunity_id: item.opportunity_id || '',
      invoice_number: item.invoice_number || '',
      total_amount: item.total_amount || '',
      due_date: item.due_date ? moment(item.due_date).format(DATE_FORMAT) : '',
      quote_number: item.quote_number || '',
      order_date: item.order_date ? moment(item.order_date).format(DATE_FORMAT) : '',
      payment_terms: item.payment_terms || '',
    }
  }
}
