<template>
  <b-dropdown
    :text="previewText"
    block
    variant="outline-primary"
    menu-class="w-100"
    @hidden="onHidden"
  >
    <BRow class="hero-month-year-picker">
      <BCol class="month">
        <b-dropdown-item
          v-for="monthOption in monthOptions"
          :key="monthOption.value"
          :active="getMonthOptionValue(monthOption.value) === month"
          href="#"
          @click.native.capture.stop="month = getMonthOptionValue(monthOption.value)"
        >
          {{ monthOption.text }}
        </b-dropdown-item>
      </BCol>
      <BCol class="year">
        <b-dropdown-item
          v-for="yearOption in yearOptions"
          :key="yearOption.value"
          :active="yearOption.value === year"
          href="#"
          @click.native.capture.stop="year = yearOption.text"
        >
          {{ yearOption.text }}
        </b-dropdown-item>
      </BCol>
    </BRow>

    <b-dropdown-divider />

    <b-dropdown-item-button
      class="clear-button"
      @click.native.capture.stop="onClear"
    >
      Clear
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BDropdownDivider,
  BDropdownItemButton,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownItemButton,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        month: '',
        year: '',
      }),
    },
    minYear: {
      type: Number,
      default: 2018,
    },
    currentYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    placeholder: {
      type: String,
      default: 'Select month & year',
    },
  },
  data() {
    return {
      month: '',
      year: '',
    }
  },
  computed: {
    monthOptions() {
      return moment.monthsShort().map((month, index) => ({
        text: month,
        value: `${index + 1}`,
      }))
    },

    yearOptions() {
      const countPrevious = this.currentYear >= this.minYear ? this.currentYear - (this.minYear - 1) : 7
      const years = Array.from({ length: countPrevious }, (_, i) => `${this.currentYear - countPrevious + i + 1}`)

      return years.map(year => ({
        text: year,
        value: year,
      }))
    },

    previewText() {
      if (!this.month && !this.year) return this.placeholder

      return `${this.month} / ${this.year}`
    },
  },
  watch: {
    value(newValue) {
      this.month = this.getMonthOptionValue(newValue.month)
      this.year = newValue.year
    },
  },
  created() {
    this.month = this.getMonthOptionValue(this.value.month)
    this.year = this.value.year
  },
  methods: {
    onHidden() {
      if (!this.month || !this.year) {
        this.month = ''
        this.year = ''
      }

      this.$emit('input', {
        month: this.month,
        year: this.year,
      })
    },

    onClear() {
      this.month = ''
      this.year = ''
    },

    getMonthOptionValue(monthOptionValue) {
      if (!monthOptionValue) return ''
      return monthOptionValue.length === 2 ? monthOptionValue : `0${monthOptionValue}`
    },
  },
}
</script>

<style lang="scss" scoped>
.hero-month-year-picker {
  .month {
    max-height: 220px;
    overflow: auto;
  }
  .year {
    max-height: 220px;
    overflow: auto;
  }
}
.clear-button {
  ::v-deep button {
    width: 100%;
    text-align: center;
  }
}
</style>
