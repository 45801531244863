import Stage from '@/models/Opportunity/Stage'

export default class StageFactory {
  static createFromJson(json) {
    return new Stage(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(StageFactory.createFromJson(item))
    })

    return jsonData
  }
}
