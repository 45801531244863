<template>
  <b-modal
    :id="modalId"
    :title="`${approvalstatus} Approval Request`"
    scrollable
    cancel-title="Reject"
    ok-title="Approve"
    cancel-variant="outline-secondary"
    size="xl"
    button-size="md"
    @show="getApprovalData"
  >
    <b-overlay
      no-wrap
      spinner-variant="primary"
      :show="showOverlay"
    />
    <div>
      <b-row class="mx-1">
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="edited-by"
            :value="editedby"
            label="Edited By"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="edited-at"
            :value="editedat"
            label="Edited At"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="approved-by"
            :value="approvedby"
            label="Approved By"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="approved-at"
            :value="approvedat"
            label="Approved At"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="disapproved-by"
            :value="disapprovedby"
            label="Disapproved By"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="disapproved-at"
            :value="disapprovedat"
            label="Disapproved At"
          />
        </b-col>
      </b-row>
      <b-row v-if="approvaltype === 'Delete Cycle'">
        <b-col cols="12" class="mb-1">
          <div class="bg-light-danger pt-1 pb-1 pl-1">
            NOTE: This is an approval request for DELETE Cycle.
          </div>
        </b-col>
      </b-row>
      <b-row v-else-if="approvaltype === 'Add New Cycle'">
        <b-col cols="12" class="mb-1">
          <div class="bg-light-primary pt-1 pb-1 pl-1">
            NOTE: This is an approval request for New Cycle.
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="approvaltype && pendingApprovalDetail && pendingApprovalDetail.length > 0">
      <div v-for="(cycle, cycleindex) in pendingApprovalDetail" :key="'viewindex'+cycleindex">
        <PendingApprovalView
          :kpi-options="kpiUnitOptions"
          :cycle="cycle"
          :columns="getColumns(cycle)"
          :updateimporttype="!cycle.is_deleted && updateimporttype"
        />
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="outline-primary" class="float-right ml-1" @click="$bvModal.hide(modalId)">
          Cancel
        </b-button>
        <template v-if="$can('Ack', 'Campaigns > Campaign') && approvalstatus === 'Pending'">
          <b-button variant="outline-danger" class="float-right ml-1" @click="RejectRequest()">
            Reject
          </b-button>
          <b-button variant="outline-success" class="float-right ml-1" @click="ApproveRequest()">
            Acknowledged
          </b-button>
        </template>
      </div>
    </template>
  </b-modal>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import ErrorService from '@/services/ErrorService'
import DataFormatService from '@/services/DataFormatService'
import PendingApprovalView from './ApprovalView.vue'

export default {
  components: {
    HeroDisplayText,
    PendingApprovalView,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    approvalId: {
      type: String,
      required: false,
      default: '',
    },
    kpiUnitOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOverlay: false,
      approvaltype: '',
      updateimporttype: true,
      approvalstatus: '',
      editedby: '',
      editedat: '',
      approvedby: '',
      approvedat: '',
      disapprovedby: '',
      disapprovedat: '',
      pendingApprovalDetail: [],
      pendingApprovalId: '',
      columns: [
        {
          label: 'Product',
          field: 'product',
          childlength: 1,
          stickyColumn: true,
        },
        {
          label: 'Net Budget (THB)',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'Ads.Budget (THB)',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'Management Fee(+Buffer)',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'KPI Per Cycle',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'KPI Unit',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'CP KPI Unit (THB)',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'Comment',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'Status',
          childlength: 2,
          stickyColumn: false,
        },
        {
          label: 'Start Date',
          childlength: 2,
          stickyColumn: false, // product_start_date
        },
        {
          label: 'End Date',
          childlength: 2,
          stickyColumn: false,
        },
      ],
    }
  },
  methods: {
    getColumns(cycle) {
      if (cycle.is_deleted) {
        return this.columns.map(column => ({
          ...column,
          childlength: 1,
        }))
      }
      return this.columns
    },

    async getApprovalData() {
      this.showOverlay = true
      this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are loading solution plan.' })

      try {
        let response
        if (this.approvalId) {
          response = await AxiosService.get(Constant.apiURL.pendingApprovals.replace('{0}', this.approvalId), null, false)
        } else {
          response = await AxiosService.get(Constant.apiURL.pendingCampaignApprovals.replace('{0}', this.$route.params.id), null, false)
        }

        if (response && response.status === true) {
          this.pendingApprovalDetail = []
          this.pendingApprovalId = response.data.id
          if (response.data && response.data.changes) {
            this.approvaltype = response.data.approval_type || ''
            if (this.approvaltype !== 'Media Plan Update' && this.approvaltype !== 'Media Plan Import' && this.approvaltype !== 'Import Planner Template') {
              this.updateimporttype = false
              this.columns.forEach(element => {
                // eslint-disable-next-line no-param-reassign
                element.childlength = 1
              })
            }
            this.approvalstatus = response.data.approval_status || ''
            this.editedby = response.data.edited_by_name || ''
            this.editedat = DataFormatService.formatEnglishDateTimeDefault(response.data.edited_at) || ''
            this.approvedby = response.data.approved_by_name || ''
            this.approvedat = response.data.approved_at || ''
            this.disapprovedby = response.data.disapproved_by_name || ''
            this.disapprovedat = response.data.disapproved_at || ''

            if (response.data.changes.cycles) {
              this.pendingApprovalDetail = this.getCycleChangeList(response.data.changes.cycles)
            }
          }
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.pendingApprovalDetail = null
      }

      this.showOverlay = false
      this.$swal().close()
    },

    async ApproveRequest() {
      this.showOverlay = true
      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are approving this solution plan.' })
        const payload = {
          remark: 'testing approve remark for update media plan',
        }
        const response = await AxiosService.post(Constant.apiURL.pendingApprovalsApprove.replace('{0}', this.pendingApprovalId), null, payload)

        if (response.status === true) {
          this.$store.state.heroAiCampaign.isPendingApproval = false
          this.$swal({ ...SweetAlert.success, text: response.message })
          this.$bvModal.hide(this.modalId)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
      this.showOverlay = false
    },
    async RejectRequest() {
      this.showOverlay = true
      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are disapproving this solution plan.' })
        const payload = {
          remark: 'testing disapprove remark',
        }
        const response = await AxiosService.post(Constant.apiURL.pendingApprovalsReject.replace('{0}', this.pendingApprovalId), null, payload)

        if (response.status === true) {
          this.$store.state.heroAiCampaign.isPendingApproval = false
          this.$swal({ ...SweetAlert.success, text: response.message })
          this.$bvModal.hide(this.modalId)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
      this.showOverlay = false
    },

    getCycleChangeList(cycles) {
      const isCycleChanged = cycle => {
        const isProductChanges = []
        cycle.products.forEach(product => {
          isProductChanges.push(product.children.some(productData => this.isRowDataChanged(productData)))
        })

        return isProductChanges.some(isProductChange => isProductChange)
      }

      const cycleMapList = cycles.map(cycle => ({
        ...cycle,
        isChanged: cycle.is_deleted || isCycleChanged(cycle),
      }))

      return cycleMapList.filter(cycle => cycle.isChanged)
    },

    isRowDataChanged(product) {
      const { formatMoney } = DataFormatService

      const isNetBudgetChanged = formatMoney(product.net_budget) !== formatMoney(product.net_budget_payload)
      const isAdsBudgetChanged = formatMoney(product.ads_budget) !== formatMoney(product.ads_budget_payload)
      const isManagementFeeChanged = formatMoney(product.management_fee) !== formatMoney(product.management_fee_payload)
      const isKpiCycleChanged = formatMoney(product.kpi) !== formatMoney(product.kpi_payload)
      const isKpiUnitNameChanged = product.kpi_unit_id !== product.kpi_unit_id_payload
      const isCostPerUnitChanged = formatMoney(product.cost_per_unit) !== formatMoney(product.cost_per_unit_payload)
      const isCommentChanged = product.comment !== product.comment_payload
      const isProductStatusNameChanged = product.product_status_name !== product.product_status_name_payload
      const isProductStartDateChanged = product.product_start_date !== product.product_start_date_payload
      const isProductEndDateChanged = product.product_end_date !== product.product_end_date_payload

      return (
        isNetBudgetChanged
          || isAdsBudgetChanged
          || isManagementFeeChanged
          || isKpiCycleChanged
          || isKpiUnitNameChanged
          || isCostPerUnitChanged
          || isCommentChanged
          || isProductStatusNameChanged
          || isProductStartDateChanged
          || isProductEndDateChanged
          || product.is_new_product
          || product.is_deleted
      ) && !product.is_total
    },
  },
}
</script>
