<template>
  <div>
    <b-row v-if="products != null" style="margin-top: -25px">
      <b-table-simple hover caption-top responsive bordered class="pending-approval-table" style="text-align: center; font-weight: normal; font-size: 12px; letter-spacing: 0px;">
        <b-thead head-variant="dark">
          <b-tr>
            <b-th v-for="(column, index) in columns" :key="index" style="vertical-align: middle; font-size: 11px;" :colspan="column.childlength" :sticky-column="column.stickyColumn">
              {{ column.label }}
            </b-th>
          </b-tr>
          <b-tr v-if="updateimporttype">
            <b-th v-for="(column, index) in childcolumns" :key="index" style="vertical-align: middle; font-size: 11px;" :sticky-column="column.stickyColumn">
              {{ column.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(product, index) in products" :key="index">
          <b-tr>
            <b-th style="text-align: left; background-color:grey; color: white;" colspan="21">
              {{ product.product }}
            </b-th>
          </b-tr>
          <b-tr v-for="(childData, childIndex) in product.children" :key="childIndex" :class="rowClass(childData)">
            <b-td v-if="!childData.is_total" :class="tdRowClass(childData)" sticky-column>
              <div class="span span:hover">
                <img
                  :src="getImage(childData.product_family_name)"
                  alt=""
                  width="15"
                  height="15"
                >
                {{ childData.name }}
              </div>
            </b-td>
            <b-td v-else-if="childData.is_total && !childData.product_family_id" colspan="1" variant="primary" sticky-column>
              Media Total
            </b-td>
            <b-td v-else variant="secondary" colspan="1" sticky-column>
              Total
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td v-else-if="childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td v-else class="text-right" variant="primary">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-right"
              :class="{ 'text-dark-success': formatMoney(childData.net_budget) !== formatMoney(childData.net_budget_payload) }"
            >
              {{ formatMoney(childData.net_budget_payload) }}
            </b-td>
            <b-td v-else-if="updateimporttype && childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.net_budget_payload) }}
            </b-td>
            <b-td v-else-if="updateimporttype" class="text-right" variant="primary">
              {{ formatMoney(childData.net_budget_payload) }}
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td v-else-if="childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td v-else class="text-right" variant="primary">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>

            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-right"
              :class="{ 'text-dark-success': formatMoney(childData.ads_budget) !== formatMoney(childData.ads_budget_payload) }"
            >
              {{ formatMoney(childData.ads_budget_payload) }}
            </b-td>
            <b-td v-else-if="updateimporttype && childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.ads_budget_payload) }}
            </b-td>
            <b-td v-else-if="updateimporttype" class="text-right" variant="primary">
              {{ formatMoney(childData.ads_budget_payload) }}
            </b-td>

            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.management_fee) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-right"
              :class="{ 'text-dark-success': formatMoney(childData.management_fee) !== formatMoney(childData.management_fee_payload) }"
            >
              {{ formatMoney(childData.management_fee_payload) }}
            </b-td>

            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.kpi) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-right"
              :class="{ 'text-dark-success': formatMoney(childData.kpi) !== formatMoney(childData.kpi_payload) }"
            >
              {{ formatMoney(childData.kpi_payload) }}
            </b-td>

            <b-td v-if="!childData.is_total" style="min-width: 100px;">
              {{ getKPIUnitName(childData.kpi_unit_id) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              :class="{ 'text-dark-success': childData.kpi_unit_id !== childData.kpi_unit_id_payload }"
              style="min-width: 100px;"
            >
              {{ getKPIUnitName(childData.kpi_unit_id_payload) }}
            </b-td>

            <b-td v-if="!childData.is_total" class="text-right" style="width: 100px;">
              {{ formatMoney(childData.cost_per_unit) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-right"
              :class="{ 'text-dark-success': formatMoney(childData.cost_per_unit) !== formatMoney(childData.cost_per_unit_payload) }"
              style="width: 100px;"
            >
              {{ formatMoney(childData.cost_per_unit_payload) }}
            </b-td>

            <b-td
              v-if="childData.is_total && childData.product_family_id"
              class="text-center"
              colspan="16"
              variant="secondary"
            >
              {{ leadsbyproductfamilyid(childData.product_family_id, index) }}
            </b-td>
            <b-td
              v-else-if="childData.is_total && !childData.product_family_id"
              class="text-center"
              colspan="16"
              variant="primary"
            >
              {{ leadsbyproductfamilyid(childData.product_family_id, index) }}
            </b-td>
            <b-td v-if="!childData.is_total" role="cell">
              <div class="span span:hover">
                {{ childData.comment }}
              </div>
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              role="cell"
              :class="{ 'text-dark-success': childData.comment !== childData.comment_payload }"
            >
              <div class="span span:hover">
                {{ childData.comment_payload }}
              </div>
            </b-td>
            <b-td v-if="!childData.is_total" class="text-center align-item-center">
              <b-badge
                v-if="childData.product_status_name"
                :variant="getBadgeVariant(childData.product_status_name)"
                class="mb-1"
              >
                {{ childData.product_status_name }}
              </b-badge>
            </b-td>
            <b-td v-if="updateimporttype && !childData.is_total" class="text-center align-item-center">
              <b-badge
                v-if="childData.product_status_name_payload"
                :variant="getBadgeVariant(childData.product_status_name)"
                class="mb-1"
              >
                {{ childData.product_status_name_payload }}
              </b-badge>
            </b-td>

            <b-td v-if="!childData.is_total" class="text-center align-item-center" style="min-width: 100px;">
              {{ formatUsaDate(childData.product_start_date) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-center align-item-center"
              :class="{ 'text-dark-success': childData.product_start_date !== childData.product_start_date_payload }"
              style="min-width: 100px;"
            >
              {{ formatUsaDate(childData.product_start_date_payload) }}
            </b-td>

            <b-td v-if="!childData.is_total" class="text-center align-item-center" style="min-width: 100px;">
              {{ formatUsaDate(childData.product_end_date) }}
            </b-td>
            <b-td
              v-if="updateimporttype && !childData.is_total"
              class="text-center align-item-center"
              :class="{ 'text-dark-success': childData.product_end_date !== childData.product_end_date_payload }"
              style="min-width: 100px;"
            >
              {{ formatUsaDate(childData.product_end_date_payload) }}
            </b-td>
          </b-tr>
          <b-tr v-if="product.product == 'Media Products'">
            <b-td style="text-align: left;" colspan="21">
              <span>
                Media Plan: <a :href="getMediaPlanLink" target="blank">{{ getMediaPlanLink }}</a>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td variant="primary" sticky-column>
              Grand Total
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.net_budget) }}
            </b-td>
            <b-td v-if="updateimporttype" variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.net_budget_payload) }}
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.ads_budget) }}
            </b-td>
            <b-td v-if="updateimporttype" variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.ads_budget_payload) }}
            </b-td>
            <b-td class="text-center" colspan="16" variant="primary">
              {{ getGrandTotal.kpi_summary }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-row>
  </div>
</template>
<script>
import DataFormatService from '@/services/DataFormatService'
import Constant from '@/utils/Constant'

export default {
  props: {
    products: {
      type: Array,
      default: null,
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
    updateimporttype: {
      type: Boolean,
      default: true,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      childcolumns: [
        {
          label: '',
          field: '',
          stickyColumn: true,
        },
        {
          label: 'Old',
          field: 'net_budget',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'net_budget_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'ads_budget',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'ads_budget_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'management_fee',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'management_fee_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'kpi',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'kpi_cycle_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'kpi_uni_id',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'kpi_unit_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'cost_per_unit',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'cp_kpi_unit_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'comment',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'comment_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'status',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'status_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'product_start_date',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'product_start_date_payload',
          stickyColumn: false,
        },
        {
          label: 'Old',
          field: 'product_end_date',
          stickyColumn: false,
        },
        {
          label: 'New',
          field: 'product_end_date_payload',
          stickyColumn: false,
        },
      ],
    }
  },
  computed: {
    getGrandTotal() {
      const { formatMoney } = DataFormatService

      const ProductList = []
      const GrandTotalArray = []
      this.products.forEach(products => {
        const childrens = products.children.filter(x => x.is_total === false)
        childrens.forEach(childData => {
          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
          ProductList.push(childData)
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${formatMoney(kpidata.value)} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      const TotalNetBudget = ProductList.map(x => parseFloat(x.net_budget || 0))
      const TotalNet = TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
      const TotalAdsBudget = ProductList.map(x => parseFloat(x.ads_budget || 0))
      const TotalAds = TotalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)

      const TotalNetBudgetPayload = ProductList.map(x => parseFloat(x.net_budget_payload || 0))
      const TotalNetPayload = TotalNetBudgetPayload.reduce((partialSum, a) => partialSum + a, 0)
      const TotalAdsBudgetPayload = ProductList.map(x => parseFloat(x.ads_budget_payload || 0))
      const TotalAdsPayload = TotalAdsBudgetPayload.reduce((partialSum, a) => partialSum + a, 0)
      return {
        net_budget: TotalNet, ads_budget: TotalAds, net_budget_payload: TotalNetPayload, ads_budget_payload: TotalAdsPayload, kpi_summary: kpiSummaryText,
      }
    },
    getMediaPlanLink() {
      return this.$store.state.heroAiCampaign.media_briefing.media_plan_link || ''
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  methods: {
    getBadgeVariant(cycleStatus) {
      if (cycleStatus) {
        if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Active.toLowerCase()) {
          return 'success'
        }
        if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Paused.toLowerCase()) {
          return 'warning'
        }
        if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Onboarding.toLowerCase()) {
          return 'info'
        }
        if (cycleStatus.toLowerCase() === Constant.productStatusOptions.OnHold.toLowerCase())  {
          return 'primary'
        }

        return 'danger'
      }
      return ''
    },
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
    getGrandTotalForMedia() {
      const { formatMoney } = DataFormatService

      const ProductList = []
      const GrandTotalArray = []
      this.products.forEach(products => {
        const childrens = products.children.filter(x => x.is_total === false && x.is_media === true)
        childrens.forEach(childData => {
          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
          ProductList.push(childData)
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${formatMoney(kpidata.value)} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      return kpiSummaryText
    },
    getImage(type) {
      const images = require.context('@/assets/images/social-icons', false, /\.png$/)
      let imageURL = images('./Hero.png')
      if (type === 'Hero Google' || type === 'AdWords') {
        imageURL = images('./google.png')
      } else if (type === 'Facebook' || type === 'Hero Facebook') {
        imageURL = images('./facebook.png')
      } else if (type === 'Line' || type === 'Line Ads' || type === 'Hero Line') {
        imageURL = images('./line.png')
      } else if (type === 'Hero Call Tracking' || type === 'Call Tracking') {
        imageURL = images('./mobile-phone.png')
      } else if (type === 'Direct') {
        imageURL = images('./landing-page.png')
      } else if (type === 'Instagram' || type === 'Hero Instagram') {
        imageURL = images('./instagram.png')
      } else if (type === 'YouTube' || type === 'Hero Youtube') {
        imageURL = images('./youtube.png')
      } else if (type === 'Messenger' || type === 'Hero Chatbot') {
        imageURL = images('./messenger.png')
      } else if (type === 'Email' || type === 'Hero Mail') {
        imageURL = images('./gmail.png')
      } else if (type === 'Linkedin') {
        imageURL = images('./linkedin.png')
      } else if (type === 'SMS') {
        imageURL = images('./sms.png')
      } else if (type === 'Taboola') {
        imageURL = images('./taboola.png')
      } else if (type === 'Twitter') {
        imageURL = images('./twitter.png')
      } else if (type === 'Whatsapp') {
        imageURL = images('./whatsapp.png')
      }
      return imageURL
    },
    getKPIUnitName(kpiUnitId) {
      const kpiUnitName = this.kpiOptions.find(x => x.value === kpiUnitId)
      if (kpiUnitName) {
        return kpiUnitName.text
      }
      return ''
    },
    leadsbyproductfamilyid(productfamilyid, mediaindex) {
      const { formatMoney } = DataFormatService

      if (productfamilyid) {
        const mediaProducts = this.products[mediaindex].children.filter(x => x.is_total === false && x.product_family_id === productfamilyid)
        const TotalArray = []
        mediaProducts.forEach(childData => {
          const KPIUnitIndex = TotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            TotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            TotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
        })
        const KPISummary = []
        let kpiSummaryText = ''
        if (TotalArray && TotalArray.length > 0) {
          TotalArray.forEach(kpidata => {
            const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
            if (kpiUnitName && kpiUnitName.length > 0) {
              KPISummary.push(`${formatMoney(kpidata.value)} ${kpiUnitName[0].text}`)
            }
          })
          kpiSummaryText = KPISummary.join(' || ')
        }
        return kpiSummaryText
      }
      return this.getGrandTotalForMedia()
    },
    rowClass(product) {
      if (product.is_new_product || this.isRowDataChanged(product)) return 'table-success'
      if (product.is_deleted) return 'table-danger'
      return ''
    },
    tdRowClass(product) {
      if (product.is_new_product || this.isRowDataChanged(product)) return 'text-left td-transparent-success'
      if (product.is_deleted) return 'text-left td-transparent-danger'
      return 'text-left'
    },

    isRowDataChanged(product) {
      const { formatMoney } = DataFormatService

      const isNetBudgetChanged = formatMoney(product.net_budget) !== formatMoney(product.net_budget_payload)
      const isAdsBudgetChanged = formatMoney(product.ads_budget) !== formatMoney(product.ads_budget_payload)
      const isManagementFeeChanged = formatMoney(product.management_fee) !== formatMoney(product.management_fee_payload)
      const isKpiCycleChanged = formatMoney(product.kpi) !== formatMoney(product.kpi_payload)
      const isKpiUnitNameChanged = product.kpi_unit_id !== product.kpi_unit_id_payload
      const isCostPerUnitChanged = formatMoney(product.cost_per_unit) !== formatMoney(product.cost_per_unit_payload)
      const isCommentChanged = product.comment !== product.comment_payload
      const isProductStatusNameChanged = product.product_status_name !== product.product_status_name_payload
      const isProductStartDateChanged = product.product_start_date !== product.product_start_date_payload
      const isProductEndDateChanged = product.product_end_date !== product.product_end_date_payload

      return (
        isNetBudgetChanged
          || isAdsBudgetChanged
          || isManagementFeeChanged
          || isKpiCycleChanged
          || isKpiUnitNameChanged
          || isCostPerUnitChanged
          || isCommentChanged
          || isProductStatusNameChanged
          || isProductStartDateChanged
          || isProductEndDateChanged
      ) && !product.is_total
    },
  },
}
</script>

<style>
  .span {
    min-width: 100px !important; /* can be 100% ellipsis will happen when contents exceed it */
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .span:hover {
    white-space: normal;
    /* or:
    width: auto
    */
  }
  .tooltip.b-tooltip {
    width: 160px !important;
  }

  [dir] .pending-approval-table .table th, [dir] .pending-approval-table .table td {
    padding: 5px !important;
  }

  .td-transparent-success {
    background-image: none !important;
    background-color: #c3efd7 !important;
  }

  .td-transparent-danger {
    background-image: none !important;
    background-color: #f9cfcf !important;
  }

  .text-dark-success {
    color: #1c9d2d;
    font-weight: bold;
  }
</style>
