export default class ContactRole {
  constructor(item = {}) {
    return {
      contact_name: item.contact_name || '',
      role: item.role || '',
      title: item.title || '',
      primary: item.primary ? 'Yes' : 'No',
      phone: item.phone || '',
      email: item.email || '',
      account_name: item.account_name || '',
    }
  }
}
