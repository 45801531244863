export default class Contacts {
  constructor(item = {}) {
    return {
      name: item.name || '',
      account_name: item.account_name || '',
      phone: item.phone || '',
      contact_type: item.contact_type || '',
    }
  }
}
