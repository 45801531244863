import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'
import DataFormatService from '@/services/DataFormatService'

export default class AccountManagementInfo {
  constructor(item = {}) {
    return {
      heroai_link: item.heroai_link || '',
      account_management_status: item.account_management_status || '',
      campaign_start_date: DataFormatService.formatUsaDate(item.campaign_start_date) || '',
      campaign_end_date: DataFormatService.formatUsaDate(item.campaign_end_date) || '',
      // campaign_start_date: item.campaign_start_date ? moment(item.campaign_start_date).format(DATE_FORMAT) : '',
      // campaign_end_date: item.campaign_end_date ? moment(item.campaign_end_date).format(DATE_FORMAT) : '',
      sayonara_ticket: item.sayonara_tickets || '',
      client_at_risk_stage: item.client_at_risk_stage || '',
      reason_for_high_risk: item.reason_high_risk || '',
      churn_reason: item.churn_reason || '',
      churn_explanation: item.churn_explanation || '',
      churn_date: DataFormatService.formatUsaDate(item.churn_date) || '',
      // churn_date: item.churn_date ? moment(item.churn_date).format(DATE_FORMAT) : '',
    }
  }
}
