import OpportunityHistory from '@/models/Campaigns/OpportunityHistory'

export default class OpportunityHistoryFactory {
  static createFromJson(json) {
    return new OpportunityHistory(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(OpportunityHistoryFactory.createFromJson(item))
    })

    return jsonData
  }
}
