<template>
  <b-modal id="modal-retain" title="Retain Campaign" scrollable cancel-variant="outline-secondary" size="xl" button-size="sm" @show="resetModal">
    <HeroInputText v-if="!backButton" id="filter" v-model="search" placeholder="Search..." />
    <b-row>
      <b-table show-empty :items="listings" :fields="fields" striped responsive size="xl" :busy="isLoading">
        <template #cell(opportunity_name)="data">
          <router-link :to="`/opportunities/${data.item.opportunity_id}`">
            {{ data.item.opportunity_name }}
          </router-link>
        </template>
        <template v-slot:cell(selected)="row">
          <b-form-radio v-model="opportunity" name="opportunity" :value="row.item" />
        </template>
        <template v-slot:cell(retain_budget)="row">
          <HeroInputNumber
            id="retain_budget"
            v-model="row.item.retain_budget"
            type="text" name="retain_budget"
            placeholder="Retain"
            :required="true"
            :state="retainError ? false : null"
            :invalid-feedback="retainError || ''"
            @input="isBudgetExceed"
          />
        </template>
        <template v-slot:cell(budget)="row">
          {{ formatMoney(row.item.budget) }}
        </template>
        <template v-if="!secondstep" v-slot:cell(remaining_amount)="row">
          {{ formatMoney(row.item.remaining_amount) }}
        </template>
        <template v-else v-slot:cell(remaining_amount)>
          {{ formatMoney(remainingAmount) }}
        </template>
      </b-table>
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <template v-if="backButton">
          <b-button variant="dark" class="float-left" @click="resetModal()">
            Back
          </b-button>
          <b-button variant="success" :disabled="!!retainError || opportunity.retain_budget <= 0" class="float-right ml-1" @click="next()">
            Next
          </b-button>
        </template>
        <b-button v-else variant="success" :disabled="!opportunity.opportunity_id" class="float-right ml-1" @click="next()">
          Next
        </b-button>
        <b-button variant="outline-primary" class="float-right ml-1" @click="$bvModal.hide('modal-retain')">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import AxiosService from '@/services/AxiosService'
import Store from '@/store/index'
import Constant from '@/utils/Constant'
import OpportunityFactory from '@/factories/Campaign/Opportunity'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import DataFormatService from '@/services/DataFormatService'

export default {
  name: 'Retain',
  components: {
    HeroInputText,
    HeroInputNumber,
  },
  data() {
    return {
      isLoading: false,
      retainError: false,
      search: '',
      opportunity: '',
      fields: [
        { key: 'selected', label: '', sortable: false },
        'campaign_id',
        'opportunity_name',
        'duration',
        { key: 'budget', label: 'Opportunity Budget (THB)' },
        { key: 'remaining_amount', label: 'Remaining (THB)' },
      ],
      opportunities: [],
      backButton: false,
      secondstep: false,
    }
  },
  computed: {
    listings() {
      return this.opportunities.filter(opportunity => opportunity.opportunity_name.toLowerCase().includes(this.search.toLowerCase()) || opportunity.campaign_id.toLowerCase().includes(this.search.toLowerCase()))
    },
    remainingAmount() {
      return this.opportunity.remaining_amount - this.opportunity.retain_budget
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
  },
  methods: {
    isBudgetExceed() {
      this.retainError = false
      if (this.opportunity.remaining_amount < this.opportunity.retain_budget) {
        this.retainError = 'Please enter Retain Budget not over Remaining'
      }
    },
    async next() {
      if (!this.backButton) {
        this.fields = [
          'opportunity_name',
          { key: 'budget', label: 'Opportunity Budget (THB)' },
          'retain_budget',
          { key: 'remaining_amount', label: 'Remaining (THB)' },
        ]
        this.opportunities = []
        this.opportunities.push(this.opportunity)
        this.secondstep = true
        this.backButton = true
      } else {
        Store.commit('heroAiCampaign/setOpportunityId', this.opportunity.opportunity_id)
        Store.commit('heroAiCampaign/setRetainBudget', this.opportunity.retain_budget)
        this.secondstep = false
        this.$router.push({ name: 'retain-campaign', params: this.$route.params.id })
      }
    },
    async resetModal() {
      this.fields = [
        { key: 'selected', label: '', sortable: false },
        'campaign_id',
        'opportunity_name',
        'duration',
        { key: 'budget', label: 'Opportunity Budget (THB)' },
        { key: 'remaining_amount', label: 'Remaining (THB)' },
      ]
      this.search = ''
      await this.doLoadData()
      this.backButton = false
      this.secondstep = false
      this.opportunity = ''
    },
    async doLoadData() {
      this.isLoading = true
      const accountId = Store.getters['heroAiCampaign/getOpportunityOverview'].account_id
      const response = await AxiosService.get(`${Constant.apiURL.campaign.retain.opportunities}/${accountId}`)
      if (response.status) {
        this.opportunities = OpportunityFactory.createFromJsonArray(response.data)
      }
      this.isLoading = false
      this.secondstep = false
    },
  },
}
</script>
