<template>
  <b-modal
    id="process-complete-modal"
    ref="process-complete-modal"
    title="Process Confirmation"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <p class="mt-1">
      The processing of the solution plan has been completed. The system will reload the data after pressing OK button.
    </p>
    <HeroButtonAction
      type="button"
      variant="primary"
      class="mx-auto"
      @click="handleOK"
    >
      OK
    </HeroButtonAction>
  </b-modal>
</template>
<script>
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  name: 'ProcessCompleteModal',
  components: { HeroButtonAction },
  methods: {
    handleOK() {
      this.$emit('ok')
      this.$bvModal.hide('process-complete-modal')
    },
  },
}
</script>

<style scoped lang="scss">
.btn {
  width: 100px;
}
</style>
