export default class CampaignHistory {
  constructor(item = {}) {
    return {
      creator_avatar: item.creator_avatar || '',
      creator_name: item.creator_name || '',
      date: item.date || '',
      id: item.id !== null && item.id !== undefined ? item.id : '',
    }
  }
}
