import DataFormatService from '@/services/DataFormatService'

export default class DealTerms {
  constructor(item = {}) {
    return {
      risk_assessment_completed_by_finance: item.risk_assessment_completed_by_finance !== undefined && item.risk_assessment_completed_by_finance !== null ? item.risk_assessment_completed_by_finance : false,
      general_term_of_service_signed: item.general_term_of_service_Signed !== undefined && item.general_term_of_service_Signed !== null ? item.general_term_of_service_Signed : false,
      finance_suggested_payment_terms: item.finance_suggested_payment_terms || '',
      sla_type: item.SLA_type || '',
      payment_terms: item.payment_terms || '',
      sla_approved_by_AM: item.SLA_approved_by_AM !== undefined && item.SLA_approved_by_AM !== null ? item.SLA_approved_by_AM : false,
      overall_management_fee_in_percentage: DataFormatService.formatPercentage(item.overall_management_fee_in_percentage) || '',
      model: item.model || '',
    }
  }
}
