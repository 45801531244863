<template>
  <b-modal
    id="generated-solution-plan-from-platform-modal"
    ref="generated-solution-plan-from-platform-modal"
    title="Generated Solution Plan From Platform"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <div
      v-for="previewCycle in previewCycles"
      :key="previewCycle.cycleNumber"
      class="collapse-wrapper"
    >
      <div
        v-b-toggle="`collapse-${previewCycle.cycleNumber}`"
        class="collapse-header"
      >
        <div class="d-flex align-items-center col-gap-4">
          <b-button :variant="getBadgeVariant(previewCycle.statusName)">
            {{ previewCycle.statusName }}
          </b-button>
          <div class="title">
            Cycle {{ previewCycle.cycleNumber }} ({{ `${previewCycle.startDate} - ${previewCycle.endDate}` }})
          </div>
        </div>
        <feather-icon icon="ChevronDownIcon" />
      </div>
      <b-collapse
        :id="`collapse-${previewCycle.cycleNumber}`"
        :visible="previewCycle.collapseVisible"
        class="collapse-body"
      >
        <b-table
          show-empty
          sticky-header
          responsive
          :items="previewCycle.products"
          :fields="tableConfig.fields"
          :per-page="0"
          :no-sort-reset="true"
          :no-local-sorting="true"
          :tbody-tr-class="rowClass"
          class="generated-solution-plan-from-platform-table"
        />
      </b-collapse>
    </div>
    <hr>
    <div class="action">
      <HeroButtonAction
        type="button"
        variant="outline-secondary"
        @click="$bvModal.hide('generated-solution-plan-from-platform-modal')"
      >
        Cancel
      </HeroButtonAction>
      <HeroButtonAction
        type="button"
        variant="primary"
        @click="$emit('process')"
      >
        Process
      </HeroButtonAction>
    </div>
  </b-modal>
</template>

<script>
import Constant from '@/utils/Constant'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  name: 'GeneratedSolutionPlanFromPlatformModal',
  components: { HeroButtonAction },
  props: {
    generatedSolutionPlanFromPlatform: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      tableConfig: {
        fields: [
          {
            label: 'Product',
            key: 'productName',
            thStyle: {
              minWidth: '360px',
            },
          },
        ],
      },
    }
  },
  computed: {
    previewCycles() {
      if (!this.generatedSolutionPlanFromPlatform) return []

      const { displayData } = this.generatedSolutionPlanFromPlatform
      const cycleNumbers = [...new Set([...displayData.map(item => item.cycle_number)])]

      return cycleNumbers.map(cycleNumber => {
        const displayDataByCycleNumber = displayData.filter(item => item.cycle_number === cycleNumber)
        const {
          status_name: statusName,
          start_date: startDate,
          end_date: endDate,
        } = displayDataByCycleNumber[0]
        return {
          collapseVisible: false,
          cycleNumber,
          statusName,
          startDate,
          endDate,
          products: displayDataByCycleNumber.map(item => ({
            productName: item.product_name,
            inserted: item.inserted,
          })),
        }
      })
    },
  },
  methods: {
    rowClass(item) {
      return item?.inserted === 0 ? 'highlighted-row' : ''
    },

    getBadgeVariant(statusName) {
      if (statusName.toLowerCase() === Constant.productStatusOptions.Active.toLowerCase()) {
        return 'success'
      }
      if (statusName.toLowerCase() === Constant.productStatusOptions.Paused.toLowerCase()) {
        return 'warning'
      }
      if (statusName.toLowerCase() === Constant.productStatusOptions.Onboarding.toLowerCase()) {
        return 'info'
      }
      if (statusName.toLowerCase() === Constant.productStatusOptions.OnHold.toLowerCase())  {
        return 'primary'
      }

      return 'danger'
    },
  },
}
</script>

<style scoped lang="scss">
.collapse-wrapper {
  background-color: white;
  border: 1px solid #EBE9F1;
  border-radius: 4px;

  & + .collapse-wrapper {
    margin-top: 10px;
  }

  .collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 0 16px;
    .title {
      font-weight: 500;
    }
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 3;
  .btn {
    width: 100px;
    margin-top: 0;
  }
}

.generated-solution-plan-from-platform-table {
  ::v-deep.highlighted-row {
    background-color: rgba(#C3E6CB, .2);
  }
}
</style>
