import SalesHistory from '@/models/Opportunity/SalesHistory'

export default class SalesHistoryFactory {
  static createFromJson(json) {
    return new SalesHistory(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(SalesHistoryFactory.createFromJson(item))
    })

    return jsonData
  }
}
