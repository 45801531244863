<template>
  <b-tab lazy title="Products">
    <b-overlay no-wrap :show="showOverlay" />

    <b-row class="mb-2">
      <b-col class="mt-1 mt-sm-0" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
        <HeroInputText
          id="filter"
          v-model="tableConfig.filter"
          placeholder="Search"
          @input="doTableFilter(500)"
        />
      </b-col>
      <b-col order="1" cols="6" sm="4" md="3" lg="2">
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          @input="doTableFilter(100)"
        />
      </b-col>
      <b-col order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2">
        <HeroButtonModal
          v-if="$can('Edit', 'Campaigns > Products')"
          v-b-modal="'campaign-product-create-modal'"
          type="button"
          variant="success"
        >
          Add Product
        </HeroButtonModal>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(action)="data">
            <!-- !! Normal Flag -->
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none action-button"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle"
                />
              </template>

              <!-- !! Edit Button -->
              <b-dropdown-item
                v-if="$can('Edit', 'Campaigns > Products')"
                v-b-modal="'campaign-product-edit-modal'"
                @click="doEditData(data.item)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>

              <!-- !! Delete Button -->
              <b-dropdown-item
                v-if="$can('Edit', 'Campaigns > Products')"
                :disabled="data.item.cycle_count !== 0"
                @click="doDeleteData(data.item)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(price)="data">
            {{ moneyService.defaultMoneyFormat(data.item.price) }}
          </template>

          <template #cell(budget)="data">
            {{ moneyService.defaultMoneyFormat(data.item.budget) }}
          </template>

          <template #cell(net_budget)="data">
            {{ moneyService.defaultMoneyFormat(data.item.net_budget) }}
          </template>

          <template #cell(actual_spending)="data">
            {{ moneyService.defaultMoneyFormat(data.item.actual_spending) }}
          </template>

          <template #cell(cycle_list)="data">
            <div v-html="convertList(data.item.cycle_list)" />
          </template>

          <template #cell(updated_at)="data">
            {{ dataFormatService.formatEnglishDateTimeDefault(data.item.updated_at) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
    <CampaignProductCreateModal @success="onSuccess" />
    <CampaignProductEditModal :product="editProduct" @success="onSuccess" />
  </b-tab>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import MoneyService from '@/services/MoneyService'
import DataFormatService from '@/services/DataFormatService'
import {
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import CampaignProductCreateModal from '@/views/heroai/campaigns/components/view/campaign-product/CampaignProductCreateModal.vue'
import CampaignProductEditModal from '@/views/heroai/campaigns/components/view/campaign-product/CampaignProductEditModal.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    HeroButtonModal,
    HeroInputText,
    HeroTablePerPage,
    HeroTableStatus,
    HeroTablePagination,
    CampaignProductCreateModal,
    CampaignProductEditModal,
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      moneyService: MoneyService,
      dataFormatService: DataFormatService,

      campaignId: this.$route.params.id ? this.$route.params.id : '',
      editProduct: {},

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 100,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            ...this.$can('Edit', 'Campaigns > Products') && {
              label: 'action',
              key: 'action',
              stickyColumn: false,
            },
          },
          {
            label: 'product name',
            key: 'product_name',
            sortable: false,
          },
          {
            label: 'Client Product Name',
            key: 'comment',
            sortable: false,
          },
          {
            label: 'price',
            key: 'price',
            sortable: false,
          },
          {
            label: 'budget',
            key: 'budget',
            sortable: false,
          },
          {
            label: 'net budget',
            key: 'net_budget',
            sortable: false,
          },
          {
            label: 'ads budget',
            key: 'actual_spending',
            sortable: false,
          },
          {
            label: 'cycles',
            key: 'cycle_list',
            sortable: false,
          },
          {
            label: 'direct channel id',
            key: 'direct_channel_id',
            sortable: false,
          },
          {
            label: 'did phone',
            key: 'did_phone',
            sortable: false,
          },
          {
            label: 'last modified date',
            key: 'updated_at',
            sortable: false,
          },
        ],
      },
    }
  },
  watch: {
    tabIndex(value) {
      if (value === 1) {
        this.doLoadData()
      }
    },
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    async doLoadData() {
      this.showOverlay = true

      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const {
          perPage, currentPage, filter,
        } = this.tableConfig

        const url = `/campaign-product/${this.campaignId}?perPage=${perPage}&page=${currentPage}&q=${filter}`
        const response = await axios.get(url, axiosConfig)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    doEditData(product) {
      this.editProduct = { ...product }
    },

    async doDeleteData(product) {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete this product information?' })

      if (alertResult.value) {
        this.showOverlay = true

        try {
          const response = await axios.delete(`/campaign-product/${product.id}`, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    // แสดงผลข้อมูลอาร์เรย์ในรูปแบบลิส
    convertList(list) {
      let newList = '<ul>'

      list.forEach(item => {
        newList = `${newList}<li>${item}</li>`
      })

      newList = `${newList}</ul>`

      return newList
    },

    getCampaignProductOptions() {
      return this.$root.$refs.MediaPlan.getCampaignProductOptions()
    },

    onSuccess() {
      this.getCampaignProductOptions()
      this.doLoadData()
    },
  },
}
</script>
