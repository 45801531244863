<template>
  <validation-observer ref="addNewProductRules" tag="form">
    <b-row>
      <b-col cols="12" md="5">
        <validation-provider
          #default="{ errors }"
          name="product"
          :rules="$store.state.heroAiCampaign.isAddProduct ? 'required' : ''"
        >
          <HeroVueSelect
            :id="`add-new-product-${cycleIndex}`"
            v-model="selectedCampaignProductId"
            label="Add New Product"
            :options="usableCampaignProductOptions"
            :required="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="2">
        <HeroInputMoneyV2
          :id="`net-budget-${cycleIndex}`"
          v-model="productModel.net_budget"
          label="Net Budget"
          :min="0"
          :max="netBudgetMax"
          :minimum-fraction-digits="2"
          :maximum-fraction-digits="2"
          rounding-mode="trunc"
          copiable
          @greater-than-max="onGreaterThanMax"
        />
      </b-col>
      <b-col cols="12" md="2">
        <HeroInputMoneyV2
          :id="`management-fee-${cycleIndex}`"
          v-model="productModel.management_fee"
          label="Management Fee(%)"
          :min="0"
          :minimum-fraction-digits="2"
          :maximum-fraction-digits="2"
          rounding-mode="trunc"
        />
      </b-col>
      <b-col cols="12" md="2">
        <validation-provider
          #default="{ errors }"
          name="budget"
          :rules="$store.state.heroAiCampaign.isAddProduct ? 'required' : ''"
        >
          <HeroVueSelect
            :id="`wallet_budget_id-${cycleIndex}`"
            v-model="selectWalletBudgetId"
            label="Budget HeroAI Camp ID"
            :clearable="false"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            :options="$store.getters['SourceWalletBudgetOptions/getOptions']"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="1" order="1" order-md="0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-add"
          @click="addProductData"
        >
          +Add
        </b-button>
      </b-col>
      <b-col cols="12" md="10">
        <HeroTextarea
          :id="`comment-${cycleIndex}`"
          v-model="productModel.comment"
          label="Comment"
        />
      </b-col>
      <b-col cols="12" md="2">
        <HeroDisplayText
          :id="`pricing-model-text-${cycleIndex}`"
          v-model="pricing_model_text"
          custom-style="margin-top:12px"
          label="Pricing Model"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import ProductModal from '@/models/Campaigns/ProductTable'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Toastification from '@/services/Toastification'
import HeroInputMoneyV2 from '@/views/components/form/inputs/HeroInputMoneyV2.vue'
import SweetAlert from '@/services/SweetAlert'
import DataFormatService from '@/services/DataFormatService'

export default {
  components: {
    HeroInputMoneyV2,
    HeroVueSelect,
    HeroTextarea,
    HeroDisplayText,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    campaignProductOptions: {
      type: Array,
      default() {
        return []
      },
    },
    productOptions: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    cycleIndex: {
      type: [Number, String],
      required: true,
    },
    cycleCampaignProductIds: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selectWalletBudgetId: '',
      pricing_model_text: this.$store.state.heroAiCampaign.opportunity_overview.pricing_model,
      management_fee_text: 30,
      productModel: new ProductModal({}),

      // Campaign Product
      selectedCampaignProductId: '',
      usableCampaignProductOptions: [],
    }
  },
  computed: {
    netBudgetMax() {
      const sourceWalletBudgetOptions = this.$store.getters['SourceWalletBudgetOptions/getOptions']
      const currentOption = sourceWalletBudgetOptions.find(sourceWalletBudgetOption => sourceWalletBudgetOption.value === this.selectWalletBudgetId)
      return currentOption ? +currentOption.remainAmount : null
    },
  },
  watch: {
    cycleCampaignProductIds: {
      immediate: true,
      deep: true,
      async handler() {
        await this.updateCampaignProductOptions()
      },
    },

    netBudgetMax() {
      if (+this.productModel.net_budget > this.netBudgetMax) {
        this.productModel.net_budget = this.netBudgetMax
        this.onGreaterThanMax()
      }
    },
  },

  methods: {
    async addProductData() {
      let toastObject

      if (this.selectWalletBudgetId === '') {
        toastObject = Toastification.getContentError('Please select a budget.')
        this.$toast(toastObject)
        return
      }

      // แปลงค่าจาก String เป็น Float ไม่เช่นนั้นจะเปรียบเทียบค่าผิด
      const netBudget = parseFloat(this.productModel.net_budget)
      const remainAmount = parseFloat(this.$store.getters['SourceWalletBudgetOptions/getRemainAmount'](this.selectWalletBudgetId))

      const dealTerms = this.$store.state.heroAiCampaign.mediaopportunity.deal_terms.model
      const clientRequirements = this.$store.state.heroAiCampaign.mediaopportunity.client_billing.client_requirements

      if (!clientRequirements.includes('Client pays for FB/GG') && dealTerms !== 'Fee Based') {
        if (netBudget > remainAmount) {
          toastObject = Toastification.getContentError('Net budget is exceeding amount of selected budget.')
          this.$toast(toastObject)
          return
        }
      }

      this.$store.state.heroAiCampaign.isAddProduct = true
      await this.$nextTick()

      const isAddNewProductRulesValid = await this.$refs.addNewProductRules.validate()

      if (isAddNewProductRulesValid) {
        // โค้ดเก่า มันเอา product_id ไปดึงข้อมูล product เพื่อมาเติมบางส่วน
        // ให้ย้ายไปลูปข้อมูลที่จำเป็นจาก campaign product dropdown แทน
        // const ProductData = this.products.filter(x => x.id === this.productModel.id)
        const campaignProduct = this.campaignProductOptions.find(option => option.value === this.selectedCampaignProductId)

        this.productModel.id                  = null
        this.productModel.name                = campaignProduct.text
        this.productModel.is_media            = campaignProduct.is_media
        this.productModel.campaign_product_id = campaignProduct.id
        this.productModel.product_id          = campaignProduct.product_id
        this.productModel.product_family_id   = campaignProduct.product_family_id
        this.productModel.product_family_name = campaignProduct.product_family_name
        this.productModel.ads_budget          = netBudget
        this.productModel.wallet_budget_id    = this.selectWalletBudgetId
        this.productModel.wallet_budget_name  = this.$store.getters['SourceWalletBudgetOptions/getWalletName'](this.selectWalletBudgetId)
        this.productModel.is_new_product      = true
        this.productModel.is_deleted          = false

        this.$emit('addProductData', this.productModel)

        // Reset ตัว Product Model
        this.productModel = new ProductModal({})
        this.$refs.addNewProductRules.reset()
        this.selectedCampaignProductId = ''
        this.selectWalletBudgetId = ''
        this.updateCampaignProductOptions()
      }
    },

    updateCampaignProductOptions() {
      this.usableCampaignProductOptions = []

      // ถ้ามีรายการ Campaign Product Id ของ Cycle นั้น ให้เอามาวนลูปตัดให้เหลือเฉพาะที่ยังไม่อยู่ใน Cycle
      if (this.cycleCampaignProductIds.length > 0) {
        this.campaignProductOptions.forEach(campaignProduct => {
          if (!this.cycleCampaignProductIds.includes(campaignProduct.id)) {
            this.usableCampaignProductOptions.push(campaignProduct)
          }
        })
      }

      if (this.cycleCampaignProductIds.length === 0) {
        this.campaignProductOptions.forEach(campaignProduct => {
          this.usableCampaignProductOptions.push(campaignProduct)
        })
      }
    },

    onGreaterThanMax() {
      if (this.netBudgetMax) {
        this.$swal({ ...SweetAlert.error, text: `The net budget should be less than or equal to ${DataFormatService.formatMoney(this.netBudgetMax)}` })
      }
    },
  },
}
</script>
<style scoped>
.btn-add {
  margin-top: 25px;
}
</style>
