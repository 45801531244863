<template>
  <b-modal id="modal-autosync" title="Synced Media Campaigns" scrollable cancel-variant="outline-secondary" size="lg" button-size="sm">
    <b-row>
      <b-table show-empty :items="autoSyncData" :fields="fields" striped responsive size="lg" :busy="isLoading" />
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="outline-primary" class="float-right ml-1" @click="$bvModal.hide('modal-autosync')">
          Ok
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>

export default {
  name: 'AutoSync',
  props: {
    autoSyncData: {
      type: Array,
      default: new Array([]),
    },
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'product_name', label: 'Product Name' },
        { key: 'campaign_id', label: 'Campaign ID' },
        { key: 'campaign_name', label: 'Campaign Name' },
        { key: 'account_id', label: 'Account ID' },
      ],
    }
  },
}
</script>
