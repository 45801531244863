<template>
  <b-modal
    id="herovision-filter-edit-modal"
    ref="herovision-filter-edit-modal"
    title="Edit Filter"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <validation-observer ref="formA">
      <b-form @submit.stop.prevent>
        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="filter name"
              rules="required"
            >
              <HeroInputText
                id="filter-name"
                v-model="filter.name"
                label="Filter Name"
                column="4"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="DID phone"
              rules=""
            >
              <HeroInputText
                id="did-number"
                v-model="filter.did_phone"
                label="DID Phone"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="landing page field"
              rules=""
            >
              <HeroInputText
                id="landing-page-field"
                v-model="filter.landing_page_field"
                label="Landing Page Field"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mb-0">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="landing page value"
              rules=""
            >
              <HeroInputText
                id="filter-name"
                v-model="filter.landing_page_value"
                label="Landing Page Value"
                column="4"
                :required="false"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <!-- !! Forward Number Modal -->
        <b-row class="mb-2">
          <b-col cols="4">
            <span style="font-size: 1rem; font-weight: 600;">Forward Number</span>
          </b-col>
          <b-col cols="4" style="padding-left: 7px;">
            <HeroButtonModal
              v-b-modal="'herovision-filter-forward-number-modal'"
              type="button"
              variant="primary"
            >
              Edit
            </HeroButtonModal>
          </b-col>
        </b-row>

        <!-- !! Forward Number List -->
        <b-row>
          <b-col cols="8" offset="4">
            <ul style="padding-left: 7px;">
              <li v-for="(forwardNumber, index) in filter.forward_number" :key="index">
                {{ forwardNumber }}
              </li>
            </ul>
          </b-col>
        </b-row>

        <!-- !! Email Language -->
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email language"
              rules="required"
            >
              <HeroVueSelect
                id="status"
                v-model="filter.email_language_id"
                label="Email Language"
                column="4"
                :options="$store.getters['LanguageOptions/getOptions']"
                :required="false"
                :clearable="false"
                :disabled="!$can('Edit', 'Campaigns > HeroVisionX Filter')"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Email Modal -->
        <b-row class="mb-2">
          <b-col cols="4">
            <span style="font-size: 1rem; font-weight: 600;">Email List</span>
          </b-col>
          <b-col cols="4" style="padding-left: 7px;">
            <HeroButtonModal
              v-b-modal="'herovision-filter-email-modal'"
              type="button"
              variant="primary"
            >
              Edit
            </HeroButtonModal>
          </b-col>
        </b-row>

        <!-- !! Email List -->
        <b-row>
          <b-col cols="8" offset="4">
            <ul style="padding-left: 7px;">
              <li v-for="(email, index) in filter.email_lead" :key="index">
                {{ email }}
              </li>
            </ul>
          </b-col>
        </b-row>

        <!-- !! SMS Language -->
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="SMS language"
              rules="required"
            >
              <HeroVueSelect
                id="status"
                v-model="filter.sms_language_id"
                label="SMS Language"
                column="4"
                :options="$store.getters['LanguageOptions/getOptions']"
                :required="false"
                :clearable="false"
                :disabled="!$can('Edit', 'Campaigns > HeroVisionX Filter')"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! SMS Modal -->
        <b-row class="mb-2">
          <b-col cols="4">
            <span style="font-size: 1rem; font-weight: 600;">SMS List</span>
          </b-col>
          <b-col cols="4" style="padding-left: 7px;">
            <HeroButtonModal
              v-b-modal="'herovision-filter-sms-modal'"
              type="button"
              variant="primary"
            >
              Edit
            </HeroButtonModal>
          </b-col>
        </b-row>

        <!-- !! SMS List -->
        <b-row>
          <b-col cols="8" offset="4">
            <ul style="padding-left: 7px;">
              <li v-for="(sms, index) in filter.sms_lead" :key="index">
                {{ sms }}
              </li>
            </ul>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" offset="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <HeroVisionFilterForwardNumberModal :filter="filter" />
    <HeroVisionFilterEmailModal :filter="filter" />
    <HeroVisionFilterSmsModal :filter="filter" />
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import HeroVisionFilterForwardNumberModal from './HeroVisionFilterForwardNumberModal.vue'
import HeroVisionFilterEmailModal from './HeroVisionFilterEmailModal.vue'
import HeroVisionFilterSmsModal from './HeroVisionFilterSmsModal.vue'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    HeroButtonAction,
    HeroButtonModal,
    ValidationObserver,
    ValidationProvider,
    HeroVisionFilterForwardNumberModal,
    HeroVisionFilterEmailModal,
    HeroVisionFilterSmsModal,
  },
  props: {
    filter: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['herovision-filter-edit-modal'].hide()
      this.$emit('success')
    },

    async doLoadData() {
      this.showOverlay = false
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.formA.validate()

      if (validationResult) {
        const body = this.filter

        try {
          const response = await axios.patch(`/herovision-filter/update/${this.filter.id}`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.doCloseModal()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
