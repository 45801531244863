import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class CycleCampaign {
  constructor(item) {
    return {
      is_selected: item.is_selected || false,
      account_id: item.account_id,
      account_name: item.account_name,
      status: item.status ? item.status[0].toUpperCase() + item.status.substring(1) : '',
      campaign_id: item.campaign_id || '',
      campaign_name: item.campaign_name || '',
      start_date: item.start_date ? moment(item.start_date).format(DATE_FORMAT) : '',
      end_date: item.end_date ? moment(item.end_date).format(DATE_FORMAT) : '',
      daily_budget: item.daily_budget || '',
      budget: item.budget || '',
      currency: item.currency || '',
      statusVariant: item.status === 'enabled' ? 'success' : 'secondary',
    }
  }
}
