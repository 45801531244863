import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'
import ContactFactory from '@/factories/Opportunity/Contact'
import ContactRoleFactory from '@/factories/Opportunity/ContactRole'
import ProductFactory from '@/factories/Opportunity/Product'
import SalesOrderFactory from '@/factories/Opportunity/SalesOrder'
import StageFactory from '@/factories/Opportunity/Stage'
import SalesHistoryFactory from '@/factories/Opportunity/SalesHistory'
import Campaign from '../Campaign'
import DealTerms from './DealTerms'
import Incharge from './Incharge'
import Information from './Information'
import SystemInformation from './SystemInformation'
import Stage from './Stage'
import AccountManagementInfo from './AccountManagementInfo'
import ClientBillingProcess from './ClientBillingProcess'

export default class Opportunity {
  constructor(item = {}) {
    return {
      information: new Information(item.opportunity_information),
      inCharge: new Incharge(item.is_charged),
      stage: new Stage(item.stages ? item.stages : {}),
      deal_terms: new DealTerms(item.deal_terms),
      validations: {
        contract_approved_by_validators: item.validations.contract_approved_by_validators !== undefined && item.validations.contract_approved_by_validators !== null ? item.validations.contract_approved_by_validators : false,
        pnd_documents_approved_by_finance: item.validations.PND_documents_approved_by_finance !== undefined && item.validations.PND_documents_approved_by_finance !== null ? item.validations.PND_documents_approved_by_finance : false,
        custemer_paid_for_pia_pt_granted: item.validations.custemer_paid_for_PIA_PT_granted !== undefined && item.validations.custemer_paid_for_PIA_PT_granted !== null ? item.validations.custemer_paid_for_PIA_PT_granted : false,
      },
      client_billing_process: {
        finance_process: item.finance_process,
        invoice_placement_date_for_cycle_1: item.invoice_placement_date_for_cycle_1 ? moment(item.invoice_placement_date_for_cycle_1).format(DATE_FORMAT) : '',
        invoice_payment_date_for_cycle_1: item.invoice_payment_date_for_cycle_1 ? moment(item.invoice_payment_date_for_cycle_1).format(DATE_FORMAT) : '',
      },
      client_billing: new ClientBillingProcess(item.client_billing_process),
      campaign: new Campaign(item.campaign_detail),
      account_management_info: new AccountManagementInfo(item.account_management_info),
      system_information: new SystemInformation(item.system_information),
      contacts: ContactFactory.createFromJsonArray(item.contacts),
      products: ProductFactory.createFromJsonArray(item.products),
      contact_roles: ContactRoleFactory.createFromJsonArray(item.contact_roles ? item.contact_roles : []),
      sales_orders: SalesOrderFactory.createFromJsonArray(item.sales_orders ? item.sales_orders : []),
      sales_history: SalesHistoryFactory.createFromJsonArray(item.sales_history ? item.sales_history : []),
      stage_history: StageFactory.createFromJsonArray(item.stage_history ? item.stage_history : []),
    }
  }
}
