export default class OpportunityHistory {
  constructor(item = {}) {
    return {
      accountId: item.account_id || '',
      opportunityId: item.opportunity_id || '',
      name: item.name || '',
      stage: item.stage_name || '',
      action: item.action || '',
      duration: item.number_of_cycles || '',
      amount: item.amount,
      editorName: item.editor_name,
    }
  }
}
