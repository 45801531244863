<template>
  <app-collapse-item title="Web Solution Briefing" :is-visible="true">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="did-number"
          :value="web_solution.did_phone.join(', ')"
          label="DID Number"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="domain-expire-date"
          :value="getDate(web_solution.domain_expire_date)"
          label="Domain Expiring Date"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="email-lead-list"
          :value="web_solution.email_lead_list.join(', ')"
          label="Email Lead List"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="email-notification-language"
          :value="web_solution.email_language_name"
          label="Email Notification Language"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="sms-lead-number"
          :value="web_solution.sms_lead_number.join(', ')"
          label="SMS Lead Number"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="sms-notification-language"
          :value="web_solution.sms_language_name"
          label="SMS Notification Language"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="line-notification-number"
          :value="web_solution.line_notifications_email.join(', ')"
          label="Line Notification Number"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="line-language-name"
          :value="web_solution.line_notifications_language"
          label="Line Notification Language"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="landing-page-type-name"
          :value="web_solution.landing_page_type"
          label="Landing Page Type"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="landing-page-language-name"
          :value="web_solution.landing_page_language"
          label="Landing Page Language"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="landing-target-url"
          :value="web_solution.landing_target_url"
          label="Landing Target URL"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import DataFormatService from '@/services/DataFormatService'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    web_solution: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  methods: {
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
