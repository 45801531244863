<template>
  <div>
    <b-modal
      id="cycle-product-campaign"
      ref="cycle-product-campaign"
      :title="modalTitle"
      size="xl"
      hide-footer
      @shown="getCampaigns"
      @hidden="items = []"
    >
      <b-overlay no-wrap :show="showOverlay" />
      <b-row align-v="center">
        <b-col class="text-left">
          Selected Campaign Name ({{ selectedCampaign.length }})
        </b-col>
        <b-col class="text-right">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
          />
        </b-col>
        <b-col class="text-right">
          <b-form-input
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doLoadData()"
          />
        </b-col>
      </b-row>
      <b-alert
        class="mb-0 mt-1"
        :show="isIncompleteData"
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          ไม่พบรายชื่อแคมเปญในฐานข้อมูลของ HeroAIX แต่พบข้อมูลการซิงก์แคมเปญ โปรดติดต่อ Engineering Team เพื่อช่วยเพิ่มรายชื่อแคมเปญ
        </div>
      </b-alert>
      <b-table
        ref="cycle-product-campaign"
        class="mt-2"
        show-empty
        :items="items"
        :fields="fields"
        responsive
        :no-border-collapse="false"
        :per-page="tableConfig.perPage"
        :current-page="tableConfig.currentPage"
      >
        <template #head(is_selected)>
          <b-form-checkbox v-model="isAllSelected" @change="toggleAll" />
        </template>
        <template #cell(is_selected)="row">
          <b-form-checkbox v-model="row.item.is_selected" @change="toggleRow(row.item.is_selected, row.item)" />
        </template>
        <template #cell(status)="row">
          <b-badge
            v-if="row.item.status"
            pill
            :variant="row.item.statusVariant"
          >
            {{ row.item.status }}
          </b-badge>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(start_date)="row">
          {{ row.item.start_date || '-' }}
        </template>
        <template #cell(end_date)="row">
          {{ row.item.end_date || '-' }}
        </template>
        <template #cell(daily_budget)="row">
          {{ formatMoney(row.item.daily_budget) || '-' }}
        </template>
        <template #cell(budget)="row">
          {{ formatMoney(row.item.budget) || '-' }}
        </template>
      </b-table>
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>

      <!-- !! Footer -->
      <hr>
      <b-row>
        <b-col cols="2">
          <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
            Back
          </HeroButtonAction>
        </b-col>
        <b-col
          v-if="!isGeneratedSolutionPlanUse"
          cols="2"
          offset="6"
        >
          <HeroButtonAction
            type="button"
            variant="outline-danger"
            :disabled="isIncompleteData"
            @click="doDeleteSync"
          >
            Delete Channel Sync
          </HeroButtonAction>
        </b-col>
        <b-col
          v-if="!isGeneratedSolutionPlanUse"
          cols="2"
        >
          <HeroButtonAction
            type="button"
            variant="primary"
            :disabled="!items.length || selectedCampaign.length <= 0"
            @click="updateCampaign"
          >
            Save
          </HeroButtonAction>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
import Constant from '@/utils/Constant'
import AxiosService from '@/services/AxiosService'
import SweetAlert from '@/services/SweetAlert'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import CycleCampaignFactory from '@/factories/Campaign/CycleCampaign'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import Toastification from '@/services/Toastification'
import DataFormatService from '@/services/DataFormatService'
import { mapState } from 'vuex'

export default {
  components: {
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    HeroButtonAction,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showOverlay: false,
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
      },
      campaign_all: false,
      campaign: false,
      count: 1,
      fields: [
        { key: 'is_selected', label: '' },
        { key: 'status', label: 'Status' },
        { key: 'campaign_id', label: 'Campaign Id' },
        { key: 'campaign_name', label: 'Campaign Name' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'end_date', label: 'End Date' },
        { key: 'daily_budget', label: 'Daily budget' },
        { key: 'budget', label: 'Lifetime Budget' },
      ],
      items: [],
      campaigns: [],
      selectedCampaign: [],
    }
  },
  computed: {
    ...mapState({
      isGeneratedSolutionPlanUse: state => state.heroAiCampaign.campaign.generated_solution_plan_use,
    }),

    isAllSelected: {
      set(value) {
        return value
      },
      get() {
        const itemCount = this.items.length
        const selectedItemCount = this.items.filter(item => item.is_selected === true).length
        const selectedCampaignCount = this.selectedCampaign.filter(item => item.is_selected === true).length
        if (selectedCampaignCount) {
          return selectedCampaignCount === itemCount && selectedCampaignCount === selectedItemCount && selectedItemCount === itemCount
        }
        return false
      },
    },

    modalTitle() {
      const { name, channel_id: channelId } = this.product
      return `${name} [${channelId}] - Edit Channels`
    },

    isIncompleteData() {
      return !!this.items.length && this.items.every(item => !item.account_id)
    },
  },
  methods: {
    formatMoney: DataFormatService.formatMoney,
    async getCampaigns() {
      try {
        this.campaigns = []
        if (this.product && this.product.product_family_name === 'Hero TikTok') {
          this.fields = [
            { key: 'is_selected', label: '' },
            { key: 'status', label: 'Status' },
            { key: 'campaign_id', label: 'Campaign Id' },
            { key: 'campaign_name', label: 'Campaign Name' },
            { key: 'daily_budget', label: 'Daily budget' },
            { key: 'budget', label: 'Lifetime Budget' },
            { key: 'currency', label: 'Currency' },
          ]
        } else {
          this.fields = [
            { key: 'is_selected', label: '' },
            { key: 'status', label: 'Status' },
            { key: 'campaign_id', label: 'Campaign Id' },
            { key: 'campaign_name', label: 'Campaign Name' },
            { key: 'start_date', label: 'Start Date' },
            { key: 'end_date', label: 'End Date' },
            { key: 'daily_budget', label: 'Daily budget' },
            { key: 'budget', label: 'Lifetime Budget' },
          ]
        }
        this.showOverlay = true
        const qparam = {
          perPage: 100,
          page: 1,
        }
        const response = await AxiosService.get(`${Constant.apiURL.campaign.solutionPlan.campaignGoogleFB}/${this.product.id}`, qparam, false)
        this.selectedCampaign = []
        this.tableConfig = {
          filter: '',
          currentPage: 1,
          perPage: 25,
          perPageOptions: [5, 10, 25, 50, 75, 100],
          totalRows: 0,
          sortBy: 'name',
          sortDirection: 'asc',
          timeInterval: moment(),
        }
        if (response.status && response?.data) {
          this.campaigns = CycleCampaignFactory.createFromJsonArray(response.data)

          this.campaigns = this.campaigns.sort((a, b) =>  {
            if (a.is_selected === b.is_selected) return 0
            return a.is_selected ? -1 : 1
          })

          this.campaigns.forEach(campaignItem => {
            if (campaignItem.is_selected) {
              this.selectedCampaign.push(campaignItem)
            }
          })
        }
        this.doLoadData()
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))
      } finally {
        this.showOverlay = false
      }
    },

    doLoadData() {
      this.items = this.campaigns.filter(campaignItem => campaignItem.campaign_id.toLowerCase().includes(this.tableConfig.filter.toLowerCase()) || campaignItem.campaign_name.toLowerCase().includes(this.tableConfig.filter.toLowerCase()) || campaignItem.campaign_id.toLowerCase().includes(this.tableConfig.filter.toLowerCase()))
      this.tableConfig.totalRows = Number(this.items.length || 1)
    },

    toggleAll(checked) {
      if (checked) {
        this.items.map((item, index) => {
          this.items[index].is_selected = true
          const isCampaignExist = this.selectedCampaign.find(campaignItem => campaignItem.campaign_id === item.campaign_id)
          if (!isCampaignExist) {
            this.selectedCampaign.push(item)
          }
          return true
        })
      } else {
        this.items.map((item, index) => {
          this.items[index].is_selected = false
          this.selectedCampaign.splice(this.selectedCampaign.findIndex(campaignItem => campaignItem.campaign_id === item.campaign_id), 1)
          return true
        })
      }
    },
    toggleRow(checked, item) {
      if (checked) {
        this.selectedCampaign.push(item)
      } else {
        this.selectedCampaign.splice(this.selectedCampaign.findIndex(campaignItem => campaignItem.campaign_id === item.campaign_id), 1)
      }
    },

    async updateCampaign() {
      this.showOverlay = true
      const analyticCampArray = []
      this.selectedCampaign.forEach(campaignItem => {
        const { campaign_id, campaign_name } = campaignItem
        analyticCampArray.push({ campaign_id, campaign_name })
        return true
      })
      const data = {
        analytic_camp_array: analyticCampArray,
      }
      const response = await AxiosService.patch(`${Constant.apiURL.campaign.solutionPlan.campaignGoogleFB}/${this.product.id}`, {}, data)
      if (response.status) {
        this.selectedCampaign = []
        await this.$refs['cycle-product-campaign'].hide()
        this.$swal({ ...SweetAlert.success, text: response.message })
      }

      if (!response.status && response.code === 422) {
        this.$swal({ ...SweetAlert.error, text: response.message })
      }
      this.showOverlay = false
    },

    async doCloseModal() {
      await this.$refs['cycle-product-campaign'].hide()
    },

    async doDeleteSync() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete all ads campaign syncing for every cycles of this channels?' })

      if (alertResult.value) {
        const response = await AxiosService.delete(`analytic-campaign-mapping/channel/${this.product.channel_id}`)
        await this.$swal({ ...SweetAlert.success, text: response.message })

        await this.getCampaigns()
      }
    },
  },
}
</script>
