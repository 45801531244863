import DataFormatService from '@/services/DataFormatService'

export default class Campaign {
  constructor(item = {}) {
    return {
      one_time_setup_fee: item.one_time_setup_fee || '0.00',
      monthly_budget: item.monthly_budget || '',
      monthly_budget_3: item.monthly_budget_3 || '',
      duration: item?.duration?.toString() || '',
      monthly_revenue_difference: item.monthly_revenue_difference || '',
      influencer_budget: item.influencer_budget || '',
      amount: item.amount || '0.00',
      overspending_absorbed_by_herolead: item.overspendind_absorebed_by_herolead || '',
      amount_inclusive_of_tax: item.amount_inclusive_taxes || '0.00',
      sf_gross_profit: item.sf_gross_profit || '',
      sf_gross_margin: item.sf_gross_margin || '',
      sf_gross_profit_per_month: item.sf_gross_profit_per_month || '',
      facebook_budget: item.facebook_budget || '',
      google: item.google || '',
      other_media: item.other_media || '',
      non_media: item.non_media || '',
    }
  }
}
