<template>
  <validation-observer ref="cycle-product-channel-form">
    <b-form @submit.stop.prevent>
      <b-modal
        id="cycle-product-channel"
        ref="cycle-product-channel"
        :title="`Edit Channel Cycle`"
        size="lg"
        no-close-on-backdrop
        @shown="doLoadData"
      >
        <b-overlay no-wrap :show="showOverlay" />
        <b-row>
          <b-col cols="12" md="12">
            <validation-provider
              #default="{ errors }"
              name="channel name"
              rules="required"
            >
              <HeroInputText
                id="channel-name"
                v-model="channel_name"
                label="Channel Name"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col v-if="isCallTracking" cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="DID Phone"
              rules="required"
            >
              <HeroInputText
                id="did-phone"
                v-model="did_phone"
                label="DID Phone"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="kpi"
              rules="required"
            >
              <HeroInputText
                id="kpi"
                v-model="kpi"
                :required="true"
                label="KPI"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <HeroVueSelect
                id="status"
                v-model="status"
                label="Status"
                :required="true"
                :disabled="true"
                :clearable="false"
                :options="statusOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col v-if="isLandingPage" cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="target_url"
              rules="url"
            >
              <HeroInputText
                id="target_url"
                v-model="target_url"
                label="Target url"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <template #modal-footer>
          <b-row>
            <b-col cols="6">
              <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
                Back
              </HeroButtonAction>
            </b-col>
            <b-col cols="6">
              <HeroButtonAction type="button" variant="primary" @click="updateChannel">
                Save
              </HeroButtonAction>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-form>
  </validation-observer>
</template>
<script>
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    HeroInputText,
    HeroVueSelect,
    HeroButtonAction,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showOverlay: false,
      statusOptions: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      channel_name: 'Default',
      did_phone: '20262367',
      kpi: '0',
      status: 'inactive',
      target_url: '',
    }
  },
  computed: {
    isCallTracking() {
      return this.product.product_family_name === 'Hero Call Tracking'
    },
    isLandingPage() {
      return this.product.revenue_type === 'WEB & LANDING PAGE'
    },
  },
  methods: {
    async doLoadData() {
      this.showOverlay = true
      const response = await AxiosService.get(`${Constant.apiURL.campaign.solutionPlan.channelCycle}/${this.product.id}`)
      if (response.status) {
        this.channel_name = response.data.channel_name
        this.did_phone = response.data.did_phone
        this.kpi = response.data.kpi_cycle
        this.status = response.data.status
        this.target_url = response.data.target_url
      }
      this.showOverlay = false
    },
    async updateChannel() {
      this.showOverlay = true
      const validationResult = await this.$refs['cycle-product-channel-form'].validate()
      if (validationResult) {
        const data = {
          kpi_cycle: this.kpi,
        }

        if (this.isCallTracking) {
          data.call_channel_name = this.channel_name
          data.did_phone = this.did_phone
        } else {
          data.direct_channel_name = this.channel_name
          data.direct_target_url = this.target_url
        }
        const response = await AxiosService.patch(`${Constant.apiURL.campaign.solutionPlan.channelCycle}/${this.product.id}`, {}, data)
        if (response.status) {
          await this.$refs['cycle-product-channel'].hide()
          this.$swal({ ...SweetAlert.success, text: response.message })
        }
      }
      this.showOverlay = false
    },
    async doCloseModal() {
      await this.$refs['cycle-product-channel'].hide()
    },
  },
}
</script>
