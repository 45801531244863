<template>
  <app-collapse-item title="Media Briefing" :is-visible="true">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="media-plan"
          :value="media.media_plan"
          label="Media Plan"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="facebook-page-access"
          :value="media.facebook_page_access"
          label="Facebook page access, Please fill page name"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="client-url"
          :value="media.client_url"
          label="Client URL"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="content-from-am"
          :value="media.content_from_am"
          label="Content from AM/Client/Copywriter/ for question"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="service-focus"
          :value="media.service_focus"
          label="Service Focus"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="keyword-ideas"
          :value="media.keyword_ideas"
          label="Keyword Ideas"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="report_system"
          :value="media.report_system_name"
          label="Reporting System"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="ad_language"
          :value="media.ad_language_names"
          label="AD Language"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="3">
        <HeroDisplayText
          id="target-aud-location-focus"
          :value="media.target_aud_location_focus"
          label="Target Audience Location Focus"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="required-fields"
          :value="media.required_fields"
          label="Required fields from chat lead (email, surname, etc.)"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="package-selling"
          :value="media.package_selling"
          label="Package Selling"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="3">
        <HeroDisplayText
          id="uniqued-selling-point"
          :value="media.uniqued_selling_point"
          label="Uniqued Selling Point"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="3">
        <HeroDisplayText
          id="target-aud"
          :value="media.target_aud"
          label="Target Audience"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="3">
        <HeroDisplayText
          id="target-aud-level"
          :value="media.target_aud_level.join(', ')"
          label="Target Audience Level"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="3">
        <HeroDisplayText
          id="additional-information"
          :value="media.additional_information"
          label="Additional Information"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    media: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
}
</script>
