import CycleCampaign from '@/models/Campaigns/CycleCampaign'

export default class CycleCampaignFactory {
  static createFromJson(json) {
    return new CycleCampaign(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(CycleCampaignFactory.createFromJson(item))
    })

    return jsonData
  }
}
