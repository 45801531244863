<template>
  <b-modal
    id="modal-media-history"
    ref="modalmediahistory"
    title="Media History"
    scrollable
    cancel-variant="outline-secondary"
    size="lg"
    button-size="sm"
    @shown="modelopen"
  >
    <b-overlay
      no-wrap
      spinner-variant="primary"
      :show="showOverlay"
    />
    <b-row>
      <b-col sm="6">
        <b-table
          ref="campaignTable"
          show-empty
          :items="campaignhistories"
          :fields="fieldsCampaign"
          select-mode="single"
          responsive
          size="lg"
          :busy="isLoading"
          selectable
          @row-selected="onRowSelectedCampaign"
        >
          <template #cell(selected)="data">
            <img :src="data.item.creator_avatar" height="24">
          </template>
          <template #cell(date)="data">
            REC #{{ campaignhistories.length - data.index }} ({{ data.item.date.split(' ')[0] }})
          </template>
        </b-table>
      </b-col>
      <b-col sm="6">
        <b-table
          ref="mediaTable"
          show-empty
          :items="mediahistories"
          :fields="fieldsMedia"
          select-mode="single"
          responsive
          size="lg"
          :busy="isLoading"
          selectable
          @row-selected="onRowSelectedMedia"
        >
          <template #cell(selected)="data">
            <img :src="data.item.creator_avatar" height="24">
          </template>
          <template #cell(date)="data">
            REC #{{ mediahistories.length - data.index }} ({{ data.item.date.split(' ')[0] }})
          </template>
        </b-table>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" :disabled="!validateForm" class="float-right ml-1" @click="openHistory()">
          Open History
        </b-button>
        <b-button variant="outline-primary" class="float-right ml-1" @click="$bvModal.hide('modal-media-history')">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import CampaignHistoryFactory from '@/factories/History/CampaignHistory'
import MediaHistoryFactory from '@/factories/History/MediaHistory'

export default {
  name: 'MediaHistory',
  data() {
    return {
      isLoading: false,
      retainError: false,
      fieldsCampaign: [
        {
          key: 'selected', label: '', tdClass: 'text-right', thClass: 'image-cell',
        },
        { key: 'date', label: 'Campaign History' },
      ],
      fieldsMedia: [
        { key: 'selected', label: '' },
        { key: 'date', label: 'Media History' },
      ],
      campaignhistories: [],
      mediahistories: [],
      mediahistorylist: [],
      selectedCampaign: [],
      selectedMedia: [],
      isModelOpen: false,
      showOverlay: false,
    }
  },
  computed: {
    validateForm() {
      let isValid = false
      if (this.selectedMedia && this.selectedMedia.length > 0) {
        isValid = true
      }
      return isValid
    },
  },
  async mounted() {
    this.selectedCampaign = []
    this.selectedMedia = []
  },
  methods: {
    onRowSelectedCampaign(items) {
      if (!this.isModelOpen) {
        this.selectedCampaign = items
        this.selectedMedia = []
        if (items && items.length > 0) {
          this.mediahistories = this.mediahistorylist.filter(x => x.campaign_history_id === items[0].id)
          if (this.mediahistories && this.mediahistories.length > 0) {
            setTimeout(() => {
              this.$refs.mediaTable.selectRow(0)
            }, 100)
          }
        } else {
          this.mediahistories = this.mediahistorylist.filter(x => x.campaign_history_id === 0)
        }
      } else {
        this.isModelOpen = false
      }
    },
    onRowSelectedMedia(items) {
      this.selectedMedia = items
    },
    openHistory() {
      let campaignHistoryName = ''
      let mediaHistoryName = ''
      let campaignIndex
      if (this.selectedCampaign && this.selectedCampaign.length > 0) {
        campaignIndex = this.campaignhistories.findIndex(x => x.id === this.selectedCampaign[0].id)
        campaignHistoryName = `REC #${this.campaignhistories.length - campaignIndex} (${this.campaignhistories[campaignIndex].date.split(' ')[0]})`
      }

      const mediaIndex = this.mediahistories.findIndex(x => x.history_number === this.selectedMedia[0].history_number)
      mediaHistoryName = `REC #${this.mediahistories.length - mediaIndex} (${this.mediahistories[mediaIndex].date.split(' ')[0]})`

      const HistoryDetail = {
        campaignHistoryId: this.selectedCampaign && this.selectedCampaign.length > 0 ? this.selectedCampaign[0].id : '0',
        mediaHistoryNumber: this.selectedMedia[0].history_number,
        campaignHistoryName,
        mediaHistoryName,
        campaignIndex,
        mediaIndex,
      }
      this.$store.state.heroAiCampaign.historySelected = HistoryDetail
      this.$store.state.heroAiCampaign.historyState += 1
      this.$bvModal.hide('modal-media-history')
    },
    async modelopen() {
      this.showOverlay = true

      this.campaignhistories = []
      this.mediahistories = []
      this.mediahistorylist = []
      const response = await AxiosService.get(`${Constant.apiURL.salesforceCampaignHistory}/${this.$route.params.id}`)
      if (response.status) {
        this.campaignhistories = CampaignHistoryFactory.createFromJsonArray(response.data)
        this.mediahistorylist = MediaHistoryFactory.createFromJsonArray(response.data)
        this.mediahistories = this.mediahistorylist.filter(x => x.campaign_history_id === 0)
      }

      this.showOverlay = false

      this.isModelOpen = true
      const CampaignIndex = this.$store.state.heroAiCampaign.historySelected.campaignIndex
      if (CampaignIndex !== undefined && CampaignIndex !== null && CampaignIndex !== '') {
        this.$refs.campaignTable.selectRow(CampaignIndex)
      }

      const MediaIndex = this.$store.state.heroAiCampaign.historySelected.mediaIndex
      if (MediaIndex !== undefined && MediaIndex !== null && MediaIndex !== '') {
        this.$refs.mediaTable.selectRow(MediaIndex)
      }
      this.isModelOpen = false
    },
  },
}
</script>

<style>
.b-table-row-selected, .b-table-row-selected.table-active td {
  background-color: #d8d4fb !important;
}
.image-cell {
  width: 30px;
}
</style>
