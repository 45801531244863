<template>
  <validation-observer ref="addNewCycleRules" tag="form">
    <b-row v-if="cycle !== null" class="mt-1">
      <b-col cols="12" md="2">
        <HeroVueSelect
          :id="`status-${cycleIndex}`"
          v-model="cycle.cycle_status"
          label="Status"
          :options="statusOptions"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="1">
        <h5 :id="`${cycle.media_plan_id}`" style="margin-top:33px;">
          <b>{{ cycle.cycle_name }}</b>
        </h5>
      </b-col>
      <b-col cols="12" md="3">
        <validation-provider
          #default="{ errors }"
          name="start date"
          rules="required|before:@end date"
        >
          <!--  ลบเงื่อนไขสองตัวนี้ออก เพราะว่าหลายๆ แคมเปญบน AI มีวันที่เริ่มแคมเปญไม่ตรงกับ Cycle เพราะไปนับตามวันที่เริ่มสัญญาใหม่
                ถ้าไม่เอาออก ตัว datepicker จะไม่สามารถแสดงวันที่บนหน้าจอได้ ทำให้วันที่หายได้
            :min-date="campaignStartdate"
            :max-date="campaignEnddate"
            -->
          <HeroInputDate
            :id="`start-date-${cycleIndex}`"
            v-model="cycle.start_date"
            label="Start Date"
            :add-calendar="true"
            :required="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            :disable-dates="disableDates"
            @input="updateCycleDates()"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="3">
        <validation-provider
          #default="{ errors }"
          name="end date"
          rules="required|after:@start date"
        >
          <HeroInputDate
            :id="`end-date-${cycleIndex}`"
            v-model="cycle.end_date"
            label="End Date"
            :add-calendar="true"
            :required="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            class="mr-1"
            :disable-dates="disableDates"
            @input="updateCycleDates()"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="3">
        <div class="custom-cycle d-flex justify-content-end">
          <b-form-checkbox
            :id="`selected-${cycleIndex}`"
            v-model="selected"
            :name="`selected-${cycleIndex}`"
            class="cycle-btn-checkbox"
            button
            button-variant="danger"
            @change="$emit('selectChange')"
          >
            Delete
          </b-form-checkbox>
          <div class="cycle-checkbox d-flex align-items-center justify-content-center border-danger rounded-right">
            <b-form-checkbox
              :id="`selected-${cycleIndex}`"
              v-model="selected"
              :name="`selected-${cycleIndex}`"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="12">
        <HeroTextarea
          :id="`remark-${cycleIndex}`"
          v-model="cycle.remark"
          label="Remark"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import Store from '@/store/index'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

extend('after', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(target)
    const enddate = new Date(value)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'End date should be greater than or equal to start date',
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(value)
    const enddate = new Date(target)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'Start date should be less than or equal to end date',
})

export default {
  components: {
    HeroVueSelect,
    HeroInputDate,
    HeroTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    cycle: {
      type: Object,
      default: null,
    },
    campaignStartdate: {
      type: String,
      default: null,
    },
    campaignEnddate: {
      type: String,
      default: null,
    },
    cycleIndex: {
      type: Number,
      required: true,
    },
    activeChannelId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: false,
      start_date: '',
      end_date: '',
      statusOptions: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Upcoming',
          value: 'upcoming',
        },
      ],
    }
  },
  computed: {
    disableDates() {
      const { cycleDisableDates } = this.$store.state.heroAiCampaign
      if (cycleDisableDates && cycleDisableDates.length > 0) {
        const filterDates = cycleDisableDates.filter((value, index) => this.cycleIndex !== index)
        return filterDates
      }
      return []
    },
  },
  mounted() {
    const val = this.activeChannelId
    const element = document.getElementById(val)
    const headerOffset = 85
    const elementPosition = element?.getBoundingClientRect().top || 0
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  },
  methods: {
    // async deleteCycle() {
    //   const responseExist = await AxiosService.get(`${Constant.apiURL.salesforceCampaignPendingRequestExist}/${this.$route.params.id}`)
    //   let confirmMessage = `Are you sure you want to delete ${this.cycle.cycle_name}?`
    //   if (responseExist.status && responseExist.data.pending_request_exist) {
    //     confirmMessage = 'Approval request already exist. Do you want to override?'
    //   }
    //   this.$swal({
    //     title: confirmMessage,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonText: 'No',
    //     confirmButtonText: 'Yes',
    //     showCloseButton: true,
    //   }).then(async result => {
    //     if (result.isConfirmed) {
    //       const response = await AxiosService.post(`/media-plans/${this.$route.params.id}/delete-request/${this.cycle.media_plan_id}`)
    //       if (response.status) {
    //         this.$store.state.heroAiCampaign.isDeleteChange = !this.$store.state.heroAiCampaign.isDeleteChange
    //         await this.$swal('Deleted', response.message, 'success')
    //       }
    //     }
    //   })
    // },
    updateCycleDates() {
      const { cycleDisableDates } = this.$store.state.heroAiCampaign
      if (cycleDisableDates && cycleDisableDates.length > 0) {
        const newCycleDates = JSON.parse(JSON.stringify(cycleDisableDates))
        newCycleDates[this.cycleIndex] = {
          from: this.cycle.start_date.split(' ')[0],
          to: this.cycle.end_date.split(' ')[0],
        }
        Store.commit('heroAiCampaign/setCycleDisableDates', newCycleDates)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-cycle {
  margin-top: 25px;
  .cycle-btn-checkbox {
    ::v-deep .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .cycle-checkbox {
    padding: 0 0.5rem;
    .custom-checkbox {
      padding-left: 1.2rem;
    }
  }
}

</style>
