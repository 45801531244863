<template>
  <b-modal
    v-if="campaignproducts"
    id="modal-add-products-solution-plan"
    scrollable
    title="Add New Cycle"
    size="xl"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <validation-observer ref="addNewCycleRules" tag="form">
      <b-row>
        <b-col cols="12" md="3">
          <validation-provider
            #default="{ errors }"
            name="start date"
            rules="required|before:@end date"
          >
            <HeroInputDate
              id="start-date"
              v-model="start_date"
              label="Start Date"
              :add-calendar="true"
              :required="true"
              :state="errors.length > 0 ? false : null "
              :invalid-feedback="errors[0]"
              :min-date="campaignStartdate"
              :max-date="campaignEnddate"
              :disable-dates="disableDates"
            />
          </validation-provider>
        </b-col>
        <b-col cols="12" md="3">
          <validation-provider
            #default="{ errors }"
            name="end date"
            rules="required|after:@start date"
          >
            <HeroInputDate
              id="end-date"
              v-model="end_date"
              label="End Date"
              :add-calendar="true"
              :required="true"
              :state="errors.length > 0 ? false : null "
              :invalid-feedback="errors[0]"
              :min-date="campaignStartdate"
              :max-date="campaignEnddate"
              :disable-dates="disableDates"
            />
          </validation-provider>
        </b-col>
        <b-col cols="12" md="3">
          <HeroInputNumber
            id="buffer"
            v-model="buffer"
            label="Buffer"
          />
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            label-for="remaining"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600;">
                Remaining
              </span>
            </template>
            <div id="remaining">
              {{ formatMoney(remainingAmount) }}
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <HeroTextarea
            id="remark"
            v-model="newCycle.remark"
            label="Remark"
          />
        </b-col>
      </b-row>
      <b-row v-if="campaignproducts && campaignproducts.length > 0">
        <b-col>
          <b-table-simple hover large caption-top striped responsive bordered>
            <!-- <caption>Items sold in August, grouped by Country and City:</caption> -->
            <b-thead head-variant="light">
              <b-tr>
                <b-th v-for="(column ,index) in columns" :key="index">
                  {{ column.label }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(product, index) in campaignproducts" :key="index">
              <b-td width="20%" class="text-left">
                <img :src="getImage(product.product_family_name)" alt="" width="15" height="15">
                {{ product.name }}
              </b-td>
              <b-td width="12%" class="p-1">
                <!--                <validation-provider-->
                <!--                  #default="{ errors }"-->
                <!--                  name="net budget"-->
                <!--                  rules="required"-->
                <!--                  :vid="`vid-net-budget-${index}`"-->
                <!--                >-->
                <!--                  <HeroInputNumber-->
                <!--                    :id="`net_budget-${index}`"-->
                <!--                    v-model="product.net_budget"-->
                <!--                    :clearable="false"-->
                <!--                    :required="true"-->
                <!--                    :state="errors.length > 0 ? false : null "-->
                <!--                    :invalid-feedback="errors[0]"-->
                <!--                  />-->
                <!--                </validation-provider>-->

                <HeroInputMoneyV2
                  :id="`net_budget-${index}`"
                  v-model="product.net_budget"
                  :clearable="false"
                  :min="0"
                  :minimum-fraction-digits="2"
                  :maximum-fraction-digits="2"
                  rounding-mode="trunc"
                />
              </b-td>
              <b-td width="10%" class="p-1">
                <!--                <validation-provider-->
                <!--                  #default="{ errors }"-->
                <!--                  name="ads budget"-->
                <!--                  :vid="`vid-ads-budget-${index}`"-->
                <!--                >-->
                <!--                  <HeroInputNumber-->
                <!--                    :id="`ads_budget-${index}`"-->
                <!--                    v-model="product.ads_budget"-->
                <!--                    :clearable="false"-->
                <!--                    :required="true"-->
                <!--                    :state="errors.length > 0 ? false : null "-->
                <!--                    :invalid-feedback="errors[0]"-->
                <!--                    :readonly="true"-->
                <!--                  />-->
                <!--                </validation-provider>-->

                <HeroInputMoneyV2
                  :id="`ads_budget-${index}`"
                  v-model="product.ads_budget"
                  :clearable="false"
                  :min="0"
                  :minimum-fraction-digits="2"
                  :maximum-fraction-digits="2"
                  rounding-mode="trunc"
                  :disabled="true"
                />
              </b-td>
              <b-td width="10%" class="p-1">
                <!--                <validation-provider-->
                <!--                  #default="{ errors }"-->
                <!--                  name="management fee"-->
                <!--                  :rules="setManagementFee(product.id)"-->
                <!--                  :vid="`vid-management-fee-${index}`"-->
                <!--                >-->
                <!--                  <HeroInputNumber-->
                <!--                    :id="`management_fee-${index}`"-->
                <!--                    v-model="product.management_fee"-->
                <!--                    :clearable="false"-->
                <!--                    :required="true"-->
                <!--                    :state="errors.length > 0 ? false : null "-->
                <!--                    :invalid-feedback="errors[0]"-->
                <!--                  />-->
                <!--                </validation-provider>-->

                <validation-provider
                  #default="{ errors }"
                  name="management fee"
                  :rules="setManagementFee(product.id)"
                  :vid="`vid-management-fee-${index}`"
                >
                  <HeroInputMoneyV2
                    :id="`management_fee-${index}`"
                    v-model="product.management_fee"
                    :clearable="false"
                    :min="0"
                    :minimum-fraction-digits="2"
                    :maximum-fraction-digits="2"
                    rounding-mode="trunc"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-td>

              <b-td width="10%" class="p-1">
                <!--                <validation-provider-->
                <!--                  #default="{ errors }"-->
                <!--                  name="kpi per cycle"-->
                <!--                  rules="required"-->
                <!--                  :vid="`vid-kpi-per-cycle-${index}`"-->
                <!--                >-->
                <!--                  <HeroInputNumber-->
                <!--                    :id="`kpi-cycle-${index}`"-->
                <!--                    v-model="product.kpi"-->
                <!--                    :clearable="false"-->
                <!--                    :required="true"-->
                <!--                    :state="errors.length > 0 ? false : null "-->
                <!--                    :invalid-feedback="errors[0]"-->
                <!--                  />-->
                <!--                </validation-provider>-->

                <HeroInputMoneyV2
                  :id="`kpi-cycle-${index}`"
                  v-model="product.kpi"
                  :clearable="false"
                  :min="0"
                  :minimum-fraction-digits="2"
                  :maximum-fraction-digits="2"
                  rounding-mode="trunc"
                />
              </b-td>
              <b-td width="20%" class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="kpi unit"
                  rules="required"
                  :vid="`vid-kpi-unit-${index}`"
                >
                  <HeroVueSelect
                    :id="`kpi-unit-id-${index}`"
                    v-model="product.kpi_unit_id"
                    :options="kpiOptions"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-td>
              <b-td width="10%" class="p-1">
                <!--                <validation-provider-->
                <!--                  #default="{ errors }"-->
                <!--                  name="cp kpi unit"-->
                <!--                  rules="required"-->
                <!--                  :vid="`vid-cp-kpi-unit-${index}`"-->
                <!--                >-->
                <!--                  <HeroInputNumber-->
                <!--                    :id="`cost-per-unit-${index}`"-->
                <!--                    :value="product.cost_per_unit"-->
                <!--                    :clearable="false"-->
                <!--                    :required="true"-->
                <!--                    :state="errors.length > 0 ? false : null "-->
                <!--                    :invalid-feedback="errors[0]"-->
                <!--                    :readonly="true"-->
                <!--                  />-->
                <!--                </validation-provider>-->

                <HeroInputMoneyV2
                  :id="`cost-per-unit-${index}`"
                  v-model="product.cost_per_unit"
                  :clearable="false"
                  :min="0"
                  :minimum-fraction-digits="2"
                  :maximum-fraction-digits="2"
                  rounding-mode="trunc"
                  :disabled="true"
                />
              </b-td>
              <b-td width="20%" class="p-1">
                <HeroTextarea
                  id="comment"
                  v-model="product.comment"
                  :clearable="false"
                />
              </b-td>

              <!-- !! Wallet Budget Id / HeroAI Campaign Id -->
              <b-td>
                <validation-provider
                  #default="{ errors }"
                  name="wallet budget"
                  rules="required"
                  :vid="`vid-wallet-budget-id-${index}`"
                >
                  <HeroVueSelect
                    id="`wallet-budget-id-${index}`"
                    v-model="product.wallet_budget_id"
                    :clearable="false"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    :options="$store.getters['SourceWalletBudgetOptions/getOptions']"
                  />
                </validation-provider>
              </b-td>

              <b-td>
                <feather-icon
                  icon="TrashIcon"
                  @click="removeRow(index)"
                />
              </b-td>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td variant="primary">
                  Total
                </b-td>
                <b-td variant="primary">
                  {{ formatMoney(totalNetBudget) }}
                </b-td>
                <b-td variant="primary">
                  {{ formatMoney(totalAdsBudget) }}
                </b-td>
                <b-td class="text-center" colspan="7" variant="primary">
                  {{ kpiSummary }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col cols="12">
        <AddProduct
          :campaign-product-options="campaignProductOptions"
          :products="allproducts"
          :product-options="productOptions"
          :cycle-campaign-product-ids="cycleCampaignProductIds"
          :cycle-index="0"
          @addProductData="getAddProductData($event)"
        />
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="md"
          class="float-right"
          :disabled="!invalid"
          @click="validateCycle()"
        >
          Save
        </b-button>
        <b-button
          variant="danger"
          size="md"
          class="float-right mr-2"
          @click="$bvModal.hide('modal-add-products-solution-plan')"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import Ripple from 'vue-ripple-directive'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import Constant from '@/utils/Constant'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import DataFormatService from '@/services/DataFormatService'
import Toastification from '@/services/Toastification'
import HeroInputMoneyV2 from '@/views/components/form/inputs/HeroInputMoneyV2.vue'
import AddProduct from './AddProduct.vue'

extend('after', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(target)
    const enddate = new Date(value)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'End date should be greater than and equal to start date',
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(value)
    const enddate = new Date(target)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'Start date should be less than equal to end date',
})

export default {
  components: {
    HeroInputMoneyV2,
    HeroInputDate,
    HeroVueSelect,
    HeroTextarea,
    HeroInputNumber,
    AddProduct,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    campaignProductOptions: {
      type: Array,
      default() {
        return []
      },
    },
    campaignproducts: {
      type: Array,
      default() {
        return []
      },
    },
    campaignStartdate: {
      type: String,
      default: null,
    },
    campaignEnddate: {
      type: String,
      default: null,
    },
    remaining: {
      type: Number,
      default: 0,
    },
    cycleName: {
      type: String,
      default: 'cycle',
    },
    clientPayForMedia: {
      type: Boolean,
      default: false,
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
    allproducts: {
      type: Array,
      default() {
        return []
      },
    },
    productOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      passedWalletValidation: true,
      invalid: false,
      buffer: 0,
      start_date: '',
      end_date: '',
      columns: [
        {
          label: 'Product',
          field: 'name',
        },
        {
          label: 'Net Budget (THB)',
          field: 'net_budget',
        },
        {
          label: 'Ads.Budget (THB)',
          field: 'ads_budget',
        },
        {
          label: 'Management Fee(+Buffer)',
          field: 'management_fee',
        },
        {
          label: 'KPI Per Cycle',
          field: 'kpi',
        },
        {
          label: 'KPI Unit',
          field: 'kpi_unit_id',
        },
        {
          label: 'CP KPI Unit (THB)',
          field: 'cost_per_unit',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
        {
          label: 'Budget HeroAI Camp ID',
          field: 'wallet_budget_id',
          width: '200px',
        },
        {
          label: '',
          field: 'action',
        },
      ],
      newCycle: {
        cycle_name: this.cycleName,
        cycle_status: 'Onboarding',
        start_date: '',
        end_date: '',
        isNewCycle: true,
        remark: '',
        products: [],
        transfer_amount: 0,
        client_pay_for_media: this.clientPayForMedia,
      },
      campaignManagementFee: 30,
      walletBudgetOptions: [],
      cycleCampaignProductIds: [],
    }
  },
  computed: {
    walletBudgetValidation() {
      if (this.campaignproducts && this.campaignproducts.length > 0) {
        const validationData = []

        // หา Wallet Budget Id ที่ถูกเลือกเอาไว้ทั้งหมดออกมา
        const walletBudgetIds = this.campaignproducts.filter(product => product.wallet_budget_id && product.wallet_budget_id !== '').map(product => product.wallet_budget_id)

        // ตัดให้เลือกเฉพาะรายการที่ไม่ซ้ำกัน
        const uniqueWalletBudgetIds = [...new Set(walletBudgetIds)]

        // ประมวลผลรวมของ Net Budget สำหรับแต่ล่ะ Wallet ว่ามีค่ามากกว่า Remain Amount หรือไม่
        uniqueWalletBudgetIds.forEach(walletBudgetId => {
          const walletName = this.$store.getters['SourceWalletBudgetOptions/getWalletName'](walletBudgetId)
          const remainAmount = parseFloat(this.$store.getters['SourceWalletBudgetOptions/getRemainAmount'](walletBudgetId))
          let netBudgets = []

          if (this.clientPayForMedia) {
            netBudgets = this.campaignproducts.filter(product => product.wallet_budget_id === walletBudgetId && !product.is_media).map(product => parseFloat(product.net_budget))
          } else {
            netBudgets = this.campaignproducts.filter(product => product.wallet_budget_id === walletBudgetId).map(product => parseFloat(product.net_budget))
          }

          const totalNetBudget = netBudgets.reduce((sum, item) => Math.round(sum * 100) / 100 + Math.round(item * 100) / 100, 0)

          validationData.push({
            wallet_budget_id: walletBudgetId,
            remain_amount: remainAmount,
            total_net_budget: totalNetBudget,
            validate_pass: totalNetBudget <= remainAmount,
            validate_message: `Total net budget amount for '${walletName}' is higher than remain amount ${totalNetBudget - remainAmount}.`,
          })
        })

        return validationData
      }

      return []
    },

    totalNetBudget() {
      if (this.campaignproducts && this.campaignproducts.length > 0) {
        const TotalNetBudget = this.campaignproducts.filter(x => x.net_budget !== '').map(x => parseFloat(x.net_budget))
        return TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
      }
      return 0
    },
    totalNewProductNetBudget() {
      if (this.campaignproducts && this.campaignproducts.length > 0) {
        let TotalNetBudget = 0
        if (this.clientPayForMedia) {
          TotalNetBudget = this.campaignproducts.filter(x => x.net_budget !== '' && x.is_media === false).map(x => parseFloat(x.net_budget))
        } else {
          TotalNetBudget = this.campaignproducts.filter(x => x.net_budget !== '').map(x => parseFloat(x.net_budget))
        }
        return TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
      }
      return 0
    },
    totalAdsBudget() {
      if (this.campaignproducts && this.campaignproducts.length > 0) {
        const TotalAdsBudget = this.campaignproducts.filter(x => x.ads_budget !== '').map(x => parseFloat(x.ads_budget))
        return TotalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)
      }
      return 0
    },
    kpiSummary() {
      const GrandTotalArray = []
      if (this.campaignproducts && this.campaignproducts.length > 0) {
        this.campaignproducts.forEach((CampaignProduct, index) => {
          const managementFeeValue = parseFloat(CampaignProduct.management_fee)
          this.campaignproducts[index].ads_budget = parseFloat(CampaignProduct.net_budget) - ((parseFloat(CampaignProduct.net_budget) * (managementFeeValue)) / 100).toFixed(2)
          this.campaignproducts[index].cost_per_unit = CampaignProduct.kpi && CampaignProduct.kpi > 0 ? (parseFloat(CampaignProduct.net_budget) / parseFloat(CampaignProduct.kpi)).toFixed(2) : CampaignProduct.net_budget

          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === CampaignProduct.kpi_unit_id)
          const KPIPerCycle = parseInt(CampaignProduct.kpi, 10)
          if (KPIPerCycle > 0) {
            if (KPIUnitIndex > -1) {
              GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
            } else {
              GrandTotalArray.push({
                id: CampaignProduct.kpi_unit_id,
                value: KPIPerCycle,
              })
            }
          }
        })
        const KPISummary = []
        let kpiSummaryText = ''
        if (GrandTotalArray && GrandTotalArray.length > 0) {
          GrandTotalArray.forEach(kpidata => {
            const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
            if (kpiUnitName && kpiUnitName.length > 0) {
              KPISummary.push(`${kpidata.value} ${kpiUnitName[0].text}`)
            }
          })
          kpiSummaryText = KPISummary.join(' || ')
        }
        return kpiSummaryText
      }
      return ''
    },
    remainingAmount() {
      const RemainingAmount = store.getters['heroAiCampaign/getMediaCampaignRemaining']
      return RemainingAmount - this.totalNewProductNetBudget
    },
    disableDates() {
      const { cycleDisableDates } = this.$store.state.heroAiCampaign
      if (cycleDisableDates && cycleDisableDates.length > 0) {
        return this.cycleDisableDates
      }
      return []
    },
  },
  watch: {
    campaignproducts: {
      async handler() {
        if (this.$refs.addNewCycleRules) {
          this.invalid = await this.$refs.addNewCycleRules.validate()
        } else {
          this.invalid = false
        }

        this.getCycleCampaignProductIds()
      },
      deep: true,
    },
    start_date: {
      async handler() {
        if (this.$refs.addNewCycleRules) {
          this.invalid = await this.$refs.addNewCycleRules.validate()
        } else {
          this.invalid = false
        }
      },
    },
    end_date: {
      async handler() {
        if (this.$refs.addNewCycleRules) {
          this.invalid = await this.$refs.addNewCycleRules.validate()
        } else {
          this.invalid = false
        }
      },
    },
    buffer: {
      handler(newValue, oldValue) {
        let newBufferValue = newValue
        if (!newBufferValue) {
          newBufferValue = 0
        }
        let operation
        let difference = 0
        if (newBufferValue > oldValue) {
          operation = 'add'
          difference = newBufferValue - oldValue
        } else if (newBufferValue < oldValue) {
          operation = 'subtract'
          difference = oldValue - newBufferValue
        }
        if (operation) {
          this.campaignproducts.forEach((product, index) => {
            const ManagementFee = Number(this.campaignproducts[index].management_fee)
            if (operation === 'add') {
              this.campaignproducts[index].management_fee = Number(ManagementFee) + Number(difference)
            } else {
              this.campaignproducts[index].management_fee = Number(ManagementFee) - Number(difference)
            }
          })
        }
      },
    },
  },
  async created() {
    this.formatMoney = DataFormatService.formatMoney
  },
  async mounted() {
    // await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.$route.params.id)
    // await this.$store.commit('SourceWalletBudgetOptions/setSourceType', 'Campaign')
    // await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
    // await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')
  },
  methods: {
    getImage(type) {
      const images = require.context('@/assets/images/social-icons', false, /\.png$/)
      let imageURL = images('./Hero.png')
      if (type === 'Hero Google' || type === 'AdWords') {
        imageURL = images('./google.png')
      } else if (type === 'Facebook' || type === 'Hero Facebook') {
        imageURL = images('./facebook.png')
      } else if (type === 'Line' || type === 'Line Ads' || type === 'Hero Line') {
        imageURL = images('./line.png')
      } else if (type === 'Hero Call Tracking' || type === 'Call Tracking') {
        imageURL = images('./mobile-phone.png')
      } else if (type === 'Direct') {
        imageURL = images('./landing-page.png')
      } else if (type === 'Instagram' || type === 'Hero Instagram') {
        imageURL = images('./instagram.png')
      } else if (type === 'YouTube' || type === 'Hero Youtube') {
        imageURL = images('./youtube.png')
      } else if (type === 'Messenger' || type === 'Hero Chatbot') {
        imageURL = images('./messenger.png')
      } else if (type === 'Email' || type === 'Hero Mail') {
        imageURL = images('./gmail.png')
      } else if (type === 'Linkedin') {
        imageURL = images('./linkedin.png')
      } else if (type === 'SMS') {
        imageURL = images('./sms.png')
      } else if (type === 'Taboola') {
        imageURL = images('./taboola.png')
      } else if (type === 'Twitter') {
        imageURL = images('./twitter.png')
      } else if (type === 'Whatsapp') {
        imageURL = images('./whatsapp.png')
      }
      return imageURL
    },
    removeRow(index) {
      this.campaignproducts.splice(index, 1)
    },
    getAddProductData(productObj) {
      this.campaignproducts.push(productObj)
    },
    async validateCycle() {
      this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are checking the pending acknowledge.' })
      const response = await AxiosService.get(`${Constant.apiURL.salesforceCampaignPendingRequestExist}/${this.$route.params.id}`)
      this.$swal().close()

      if (response.status && response.data.pending_request_exist) {
        this.$swal({
          title: 'Approval request already exist. Do you want to override?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          showCloseButton: true,
        }).then(async resultconfirm => {
          if (resultconfirm.isConfirmed) {
            this.addNewCycle()
          }
        })
      } else {
        this.addNewCycle()
      }
    },
    addNewCycle() {
      let toastObject

      this.newCycle.products = []

      // eslint-disable-next-line consistent-return
      this.walletBudgetValidation.forEach(wallet => {
        if (wallet.validate_pass === false) {
          this.passedWalletValidation = false
          toastObject = Toastification.getContentError(wallet.validate_message)
          this.$toast(toastObject)
          return false
        }
      })

      if (this.walletBudgetValidation.length === 0) {
        this.passedWalletValidation = false
        return false
      }

      if (this.passedWalletValidation === false) {
        this.passedWalletValidation = !this.passedWalletValidation
        return false
      }

      return new Promise((resolve, reject) => {
        this.$refs.addNewCycleRules.validate().then(async success => {
          if (success) {
            this.newCycle.campaign_id = this.$route.params.id
            this.newCycle.start_date = this.start_date
            this.newCycle.end_date = this.end_date

            const Mediadata = this.campaignproducts.filter(x => x.is_media === true)

            if (Mediadata) {
              let ProductFamily = Mediadata.map(x => x.product_family_id)

              ProductFamily = [...new Set(ProductFamily)]

              const MediaProductObj = []
              const mediaGrandTotal = {
                product_family_id: '',
                net_budget: 0,
                ads_budget: 0,
                leads: '',
                is_total: true,
              }

              if (ProductFamily && ProductFamily.length > 0) {
                ProductFamily.forEach(data => {
                  const products = data
                  const FilterProduct  = Mediadata.filter(x => x.product_family_id === products)
                  const TotalNetBudget = FilterProduct.map(x => parseFloat(x.net_budget))
                  const TotalNet       = TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
                  const TotalAdsBudget = FilterProduct.map(x => parseFloat(x.ads_budget))
                  const TotalAds       = TotalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)

                  const TotalObj = {
                    product_family_id: data,
                    net_budget: TotalNet,
                    ads_budget: TotalAds,
                    leads: '',
                    is_total: true,
                  }

                  mediaGrandTotal.net_budget += TotalNet
                  mediaGrandTotal.ads_budget += TotalAds
                  FilterProduct.forEach(obj => {
                    MediaProductObj.push(obj)
                  })
                  MediaProductObj.push(TotalObj)
                })
                MediaProductObj.push(mediaGrandTotal)
              }
              const MediaObj = {
                product: 'Media Products',
                children: MediaProductObj,
              }
              this.newCycle.products.push(MediaObj)
            }

            const NonMediadata = this.campaignproducts.filter(x => x.is_media === false)
            if (NonMediadata) {
              const NomMediaObj = {
                product: 'Non Media Products',
                children: NonMediadata,
              }
              this.newCycle.products.push(NomMediaObj)
            }

            if (!this.clientPayForMedia) {
              this.newCycle.transfer_amount = NonMediadata.reduce((accumulator, product) => (+accumulator) + (+product.net_budget), 0)
            } else {
              this.newCycle.transfer_amount = this.totalNetBudget
            }

            this.newCycle.client_pay_for_media = this.clientPayForMedia

            if (this.remainingAmount < 0) {
              this.$swal('You are exceeding your limit')
              reject()
            } else if (this.campaignproducts.length > 0) {
              try {
                this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are adding cycle.' })

                const response = await AxiosService.post(`media-plans/${this.$route.params.id}/create-request`, null, this.newCycle)
                if (response.status) {
                  this.newCycle = {
                    cycle_name: this.cycleName,
                    cycle_status: 'Onboarding',
                    start_date: '',
                    end_date: '',
                    isNewCycle: true,
                    remark: '',
                    products: [],
                    transfer_amount: 0,
                    client_pay_for_media: this.clientPayForMedia,
                  }
                  this.$emit('cycleAdded')
                  await this.$swal({ ...SweetAlert.success, text: response.message })
                  this.$bvModal.hide('modal-add-products-solution-plan')
                }
              } catch (error) {
                this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
                if (error.response.status === 401) {
                  this.$router.push({ name: 'heroai-logout' })
                }
                if (error.response.status === 404) {
                  await this.$router.push({ name: 'page-not-found' })
                }
              }
            } else {
              this.$swal('Please add at least one product')
              reject()
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    setManagementFee(productId) {
      const Product = this.allproducts.filter(x => x.id === productId)
      let managementFee = 0
      if (Product && Product.length > 0) {
        managementFee = Product[0].management_fee
      }
      managementFee = 0
      return `required|between:${managementFee},100`
    },

    getCycleCampaignProductIds() {
      const mediaPlans = this.campaignproducts
      this.cycleCampaignProductIds = []

      if (mediaPlans) {
        mediaPlans.forEach(mediaPlan => {
          if (mediaPlan.campaign_product_id) {
            this.cycleCampaignProductIds.push(mediaPlan.campaign_product_id)
          }
        })
      }
    },
  },
}
</script>
