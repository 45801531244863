export default class Product {
  constructor(item = {}) {
    return {
      name: item.name || '',
      quantity: item.quantity || '',
      sales_price: item.sales_price || '',
      management_fee: item.management_fee || '',
      gross_profit: item.gross_profit || '',
      description: item.description || '',
      price: item.price || '',
      vendor_name: item.vendor_name || '',
      vendor_cost: item.vendor_cost || '',
      line_item_number: item.line_item_number || '',
    }
  }
}
