<template>
  <b-modal
    id="process-confirmation-modal"
    ref="process-confirmation-modal"
    title="Process Confirmation"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <feather-icon
      icon="InfoIcon"
      size="100"
      stroke="#7367f0"
      class="mx-auto w-100"
    />
    <p class="mt-1">
      Do you want to change the current solution plan to a new solution plan that is generated based on the data from the ads platform campaigns?
    </p>
    <ul class="list-unstyled pl-0">
      <li>
        - For first change, the solution plan will be <span class="text-danger">completely replaced</span> with newly generated data.
      </li>
      <li>
        - For subsequent change, the solution plan will be updated with new data that was not included in the previous data generation.
      </li>
    </ul>
    <b-form @submit.prevent="handleConfirm">
      <validation-provider
        #default="{ errors }"
        ref="word"
        name="word"
        :rules="rules"
      >
        <HeroInputText
          id="word"
          v-model="word"
          :label="label"
          required
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
      <hr>
      <div class="action">
        <HeroButtonAction
          type="button"
          variant="outline-secondary"
          @click="$bvModal.hide('process-confirmation-modal')"
        >
          Cancel
        </HeroButtonAction>
        <HeroButtonAction
          type="submit"
          variant="primary"
        >
          Confirm
        </HeroButtonAction>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import { extend } from 'vee-validate'

extend('confirmation_word', {
  message: 'The {_field_} is incorrect.',
  validate(value) {
    return value === 'Confirmation'
  },
})

export default {
  name: 'ProcessConfirmationModal',
  components: { HeroInputText, HeroButtonAction },
  data() {
    return {
      label: 'Type the word "Confirmation".',
      word: '',
      rules: 'required',
    }
  },
  methods: {
    onHidden() {
      this.word = ''
      this.rules = 'required'
    },

    async handleConfirm() {
      this.rules = 'required|confirmation_word'

      await this.$nextTick()

      const isWordValid = (await this.$refs.word.validate()).valid

      if (isWordValid) {
        this.$emit('confirm')
        this.$bvModal.hide('process-confirmation-modal')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .btn {
    width: 100px;
    margin-top: 0;
  }
}
</style>
