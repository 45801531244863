import CycleDropdown from '@/models/Campaigns/CycleDropdown'

export default class CycleDropdownFactory {
  static createFromJson(json) {
    return new CycleDropdown(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(CycleDropdownFactory.createFromJson(item))
    })

    return jsonData
  }
}
